import React from "react";
import StockOptions from "./StockOptions";
import FloatField from "./FloatField";

function StockField(props: {
    options?: (number | undefined)[],
    style?: React.CSSProperties,
    label: string | JSX.Element,
    labelStyle?: React.CSSProperties,
    value: number,
    setFunction: (value: number) => void,
    inputStyle?: React.CSSProperties,
    helpId?: string,
    withPopups?: boolean,
    isDirty?: boolean,
    setIsDirty?: (isDirty: boolean) => void,
}): JSX.Element {
    let options: (number | undefined)[] = props.options ? props.options : [1, 10, 16, 28];
    return (
        <FloatField {...props}>
            <StockOptions value={props.value} setFunction={props.setFunction} options={options}
                          withPopups={props.withPopups!}
                          style={{flexWrap: "nowrap", marginLeft: "1rem", marginTop: "20px"}}/>
        </FloatField>
    );
}

export default StockField;
