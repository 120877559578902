import React, {useState} from "react";
import Medication from "../../../../types/Medication";
import {
    MedicationVariantStockUpdates,
    StockUpdateFailure,
    StockUpdateFailures
} from "../../../../types/MedicationVariants";
import axios from "axios";
import {baseUrl} from "../../../../api/base";
import {useNavigate} from "react-router-dom";
import {invalidateMedicationsCache} from "../../../../api/medications";
import {useQueryClient} from "@tanstack/react-query";

function Wizard(props: { medications: Medication[] }): JSX.Element {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {medications} = props;

    const [saving, showSaving] = useState<boolean>(false);
    const [savingError, setSavingError] = useState<string>("");
    const [failure, setFailure] = useState<StockUpdateFailure>();

    const [activeStep, setActiveStep] = useState<number>(0);
    const [stockChange, setStockChange] = useState<number>(0);
    const [lessThanZero] = useState<boolean>(false);

    // useEffect(() => {
    //     setLessThanZero((medications[activeStep].stock! + stockChange) < 0);
    // }, [stockChange, activeStep, medications]);

    const adjustChange = (adjustment: number) => {
        setStockChange(stockChange + adjustment);
    };

    const nextStep = () => {
        showSaving(true);
        if (activeStep + 1 >= medications.length) {
            navigate("/medications");
        } else {
            setTimeout(() => {
                setStockChange(0);
                setActiveStep(activeStep + 1);
                showSaving(false);
            }, 500);
        }
    }

    const applyChange = () => {
        showSaving(true);
        setSavingError("");
        setFailure(undefined);

        if (stockChange === 0) {
            nextStep();
            return;
        }

        let body: MedicationVariantStockUpdates = {
            modifications: [{medicationId: medications[activeStep].medicationId!, stockChange: stockChange}],
        };

        axios.post(`${baseUrl}/medications/stock/modify`, body).then((res) => {
            let response: StockUpdateFailures = res.data;
            if (response.failures) {
                setFailure(response.failures[0]);
                showSaving(false);
            } else {
                invalidateMedicationsCache(queryClient);
                nextStep();
            }
        }).catch((err) => {
            console.log(err);
            showSaving(false);
            setSavingError("Failed to apply stock changes.");
        });
    };

    return (
        <>
            <div className="section mt-2">
                <div className="card">
                    <div className="card-header">
                        Wizard: Medication {activeStep + 1} of {medications.length}
                    </div>
                    <div className="card-body">
                        <blockquote className="blockquote mb-0">
                            <h3 className={"text-center"}>
                                {medications[activeStep].name} {medications[activeStep].unit}
                            </h3>
                            <footer className={"text-center"}>
                                {/*Existing stock: {medications[activeStep].stock ? medications[activeStep].stock : "0"}*/}
                            </footer>
                        </blockquote>
                        <h3 className={"card-text text-center text-dark mt-4 mb-4"}>
                            Stock adjustment: {stockChange > 0 ? "+" : ""}{stockChange}
                            {lessThanZero &&
                                <div className={"mt-2 text-danger"} style={{fontWeight: "bold"}}>Cannot reduce stock to
                                    less than zero!</div>}
                        </h3>
                        {failure && <div className={"text-center"}>
                            <h1 className="cart-title text-danger">Error</h1>
                            <p className="card-text text-danger">{failure.reason}</p>
                            <button className="btn btn-primary" onClick={() => {
                                showSaving(true);
                                setSavingError("");
                                setTimeout(applyChange, 500);
                            }}>Try again
                            </button>
                        </div>}
                        {savingError.length > 0 && <div className={"text-center"}>
                            <h1 className="cart-title text-danger">Error</h1>
                            <p className="card-text text-danger">{savingError}</p>
                            <button className="btn btn-primary" onClick={() => {
                                showSaving(true);
                                setSavingError("");
                                setTimeout(applyChange, 500);
                            }}>Try again
                            </button>
                        </div>}
                        {saving && savingError.length === 0 &&
                            <div className={"text-center"}>
                                <div className="spinner-border text-primary" style={{width: "60px", height: "60px"}}
                                     role="status"></div>
                            </div>}
                        {!saving && !savingError && <>
                            <div className="mb-3" style={{
                                textAlign: "right",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}>
                                <button type="button" className="btn btn-outline-secondary"
                                        style={{padding: "1rem 0.75rem"}}
                                        onClick={() => adjustChange(1)}>+1
                                </button>
                                <button type="button" className="btn btn-outline-secondary"
                                        style={{padding: "1rem 0.75rem"}}
                                        onClick={() => adjustChange(10)}>+10
                                </button>
                                <button type="button" className="btn btn-outline-secondary"
                                        style={{padding: "1rem 0.75rem"}}
                                        onClick={() => adjustChange(16)}>+16
                                </button>
                                <button type="button" className="btn btn-outline-secondary"
                                        style={{padding: "1rem 0.75rem"}}
                                        onClick={() => adjustChange(28)}>+28
                                </button>
                                <button type="button" className="btn btn-outline-secondary"
                                        style={{padding: "1rem 0.75rem"}}
                                        onClick={() => adjustChange(90)}>+90
                                </button>
                            </div>
                            <div className="mt-3" style={{
                                textAlign: "right",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}>
                                <button type="button" className="btn btn-outline-danger"
                                        style={{padding: "1rem 0.75rem"}}
                                        onClick={() => adjustChange(-1)}>-1
                                </button>
                                <button type="button" className="btn btn-outline-danger"
                                        style={{padding: "1rem 0.75rem"}}
                                        onClick={() => adjustChange(-10)}>-10
                                </button>
                                <button type="button" className="btn btn-outline-danger"
                                        style={{padding: "1rem 0.75rem"}}
                                        onClick={() => adjustChange(-16)}>-16
                                </button>
                                <button type="button" className="btn btn-outline-danger"
                                        style={{padding: "1rem 0.75rem"}}
                                        onClick={() => adjustChange(-28)}>-28
                                </button>
                                <button type="button" className="btn btn-outline-danger"
                                        style={{padding: "1rem 0.75rem"}}
                                        onClick={() => adjustChange(-90)}>-90
                                </button>
                                <button type="button" className="btn btn-outline-danger"
                                        style={{padding: "1rem 0.75rem"}}
                                        onClick={() => adjustChange(0 - stockChange)}>=0
                                </button>
                            </div>
                            <button type="button" className="btn btn-primary btn-block mt-4"
                                    disabled={lessThanZero}
                                    title={stockChange < 0 ? "Resolve the error first" : (stockChange === 0 ? "No adjustment to apply, skip this medication..." : "Apply adjustment...")}
                                    onClick={() => applyChange()}>
                                {lessThanZero ? "Resolve the error first" : (stockChange === 0 ? "No adjustment to apply, skip this medication..." : "Apply adjustment...")}
                            </button>
                        </>}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Wizard;
