import React from "react";
import {IonIcon} from "@ionic/react";
import {helpCircleOutline} from "ionicons/icons";
import {useQuery} from "@tanstack/react-query";
import {fetchHelpQueryOptionsFunc} from "../../api/help";

const HelpButton = (props: {
    id: string,
    className?: string,
    style?: React.CSSProperties,
    size?: "small" | "large",
}) => {
    const panelId = "help-" + props.id;
    const [helpLoaded, setHelpLoaded] = React.useState<boolean>(false);
    const {data: help, isLoading, isError, error, refetch} =
        useQuery(fetchHelpQueryOptionsFunc(props.id));

    const loadHelp = (force?: boolean) => {
        if (!helpLoaded || isError || force) {
            refetch().then(r => setHelpLoaded(true));
        }
    }

    return (
        <>
            <IonIcon icon={helpCircleOutline} size={props.size} className={"pointer " + props.className}
                     style={{position: "absolute", right: "-2px", top: "5px", ...props.style}}
                     data-bs-toggle="modal" data-bs-target={"#" + panelId} onClick={() => loadHelp()}/>
            <div className="modal fade modalbox" id={panelId} tabIndex={-1} style={{display: "none"}}
                 aria-modal="true" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        {isLoading && <div className="splash-page text-center mt-5 mb-5">
                            <div className="spinner-border text-primary" style={{width: "40px", height: "40px"}}
                                 role="status"></div>
                        </div>}
                        {isError && <div className="splash-page text-center mt-5 mb-5">
                            <h1 className="card-title text-danger">Error</h1>
                            <p className="card-text text-danger">{error.message}</p>
                            <button className={"btn btn-primary"} onClick={() => loadHelp(true)}>Try again</button>
                        </div>}
                        {!isLoading && !isError && help && <>
                            <div className="modal-header">
                                <h5 className="modal-title" onClick={() => loadHelp(true)}>{help.title}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                            </div>
                            <div className="modal-body" dangerouslySetInnerHTML={{__html: help.content}}/>
                        </>}
                    </div>
                </div>
            </div>
        </>
    );
}

export default HelpButton;
