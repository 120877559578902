import axios from "axios";
import {baseUrl, getAxiosRequestOptions, handleAxiosError} from "./base";
import {MedicationStockForecast} from "../types/MedicationVariants";

export const fetchStockForecast = async (): Promise<Array<{
    days: number,
    medications: MedicationStockForecast[]
}>> => {
    return axios.post(
        `${baseUrl}/medications/variants/stock/forecast/days`,
        {},
        getAxiosRequestOptions(),
    ).then((res): Array<{ days: number, medications: MedicationStockForecast[] }> => {
        for (let i = 0; i < res.data.forecast.length; i++) {
            if (!res.data.forecast[i].days) {
                res.data.forecast[i].days = 0;
            }
            if (!res.data.forecast[i].medications) {
                res.data.forecast[i].medications = [];
            }
        }
        return res.data.forecast;
    }).catch(handleAxiosError("Failed to get stock forecast"));
};
