import React from "react";
import {Link, Navigate} from "react-router-dom";
import {getQueryVariable} from "../../utils/QueryString";
import {getUserToken, removePostAuthDest} from "../../auth/store";

function Homepage(): JSX.Element {
    const to = getQueryVariable("to");
    if (getUserToken()) {
        return <Navigate to={to === "" ? "/entries" : to} replace={true}/>
    }
    if (to !== "") {
        removePostAuthDest();
    }

    return (
        <div className="section homepage">
            <div className="splash-page mt-5 mb-5">
                <div className="iconbox mb-4">
                    <img src={"/logo/logo.png"} style={{width: "100%"}} alt={"MyEPR logo"} className={"icon"}/>
                </div>
                <h2 className="mb-2">MyEPR</h2>
                <p className={"text-dark"}>Under construction.</p>
                <Link to={"https://myepr.substack.com/"}>
                    <button className={"btn btn-primary mt-4"}>Subscribe to our newsletter for updates</button>
                </Link>
                <div className={"mt-5"}>
                    <p>Test user? <Link to={"/login"}>Login here</Link>.</p>
                </div>
            </div>
        </div>
    );
}

export default Homepage;
