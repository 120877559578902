import React from "react";
import {Link, NavLink} from "react-router-dom";
import {IonIcon} from "@ionic/react";
import {
    callOutline,
    carOutline,
    chevronDownOutline,
    chevronUpOutline,
    globeOutline,
    locationOutline,
    mailOutline,
    openOutline,
    pencilOutline,
    timeOutline
} from "ionicons/icons";
import randomString from "../../utils/RandomString";

function Item(props: { hospitalTransport: boolean }): JSX.Element {
    const [showingMap, setShowingMap] = React.useState<boolean>(false);
    const randomId = randomString(8);

    return (
        <div className={"mt-2 mb-2"}>
            <div className="card">
                <div className="card-header bg-primary text-light"
                     style={{
                         borderTopLeftRadius: "1rem",
                         borderTopRightRadius: "1rem",
                     }}>
                    <div style={{flexGrow: 1, display: "flex", alignItems: "center", gap: "0.75rem"}}>
                        <IonIcon icon={timeOutline} style={{fontSize: "2rem"}}/>
                        <div>Tue 23rd Jan @ 2pm</div>
                    </div>
                    <NavLink to={"/appointments/edit/1"} className={"btn btn-icon btn-primary"}>
                        <IonIcon icon={pencilOutline} className={"pointer"} style={{fontSize: "1.5rem"}}/>
                    </NavLink>
                </div>
                <div className="card-body p-0">
                    <div className={"text-dark mt-1"} style={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: "0.5rem",
                        padding: "0.25rem 1.25rem 0 0.75rem",
                    }}>
                        <div style={{flexGrow: 1, lineHeight: "1.2rem"}}>
                            <div style={{display: "flex"}}>
                                <IonIcon icon={locationOutline} role="img"
                                         style={{marginTop: "2px", marginRight: "2px"}}/>
                                <div>
                                    <strong>Nephrology Clinic, Outpatients 1</strong><br/>
                                    Royal Berkshire Hospital, Reading, RG1 5AN
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "0.25rem",
                                gap: "0.25rem"
                            }}>
                                <IonIcon icon={callOutline} role="img"/>
                                <Link to={"tel:01183227969"}>0118 3227969</Link>
                            </div>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "0.25rem",
                                gap: "0.25rem"
                            }}>
                                <IonIcon icon={mailOutline} role="img"/>
                                <Link to={"mailto:rbb-tr.CAT9@nhs.net"}> rbb-tr.CAT9@nhs.net</Link>
                            </div>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "0.25rem",
                                gap: "0.25rem"
                            }}>
                                <IonIcon icon={globeOutline} role="img"/>
                                <Link to={"https://www.royalberkshire.nhs.uk/our-services/renal/"}
                                      style={{
                                          width: "20rem",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap"
                                      }}
                                      target={"_blank"}>
                                    Renal | Royal Berkshire NHS Foundation Trust asofih aslia gsand gkdugb
                                </Link>
                            </div>
                        </div>
                    </div>
                    {!props.hospitalTransport && <div className={"mt-2"}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                        }}>
                            <Link to={"#"} style={{display: "flex", flexDirection: "row"}}
                                  onClick={() => setShowingMap(!showingMap)}>
                                <div>Toggle map</div>
                                <IonIcon icon={showingMap ? chevronUpOutline : chevronDownOutline} role="img"
                                         style={{marginLeft: "0.25rem", marginTop: "6px"}}/>
                            </Link>
                            <Link
                                to={"https://www.google.com/maps/dir/Reading+RG1+6BJ/Reading+RG1+5AN/@51.4496828,-0.974794,15z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x48769b143890fbed:0xfebb71a7d1de1e0a!2m2!1d-0.9730615!2d51.4522039!1m5!1m1!1s0x48769b39c639c4dd:0xdd8b61da5509966d!2m2!1d-0.9570201!2d51.4480821?entry=ttu"}
                                target={"_blank"} style={{display: "flex", flexDirection: "row"}}>
                                <div>Get directions</div>
                                <IonIcon icon={openOutline} role="img"
                                         style={{marginLeft: "0.25rem", marginTop: "6px"}}/>
                            </Link>
                        </div>
                        {showingMap &&
                            <div id={"map-" + randomId} className={"slide-down text-dark mt-1 mb-1"}>
                                <img src={"/temp/map-sample.png"} alt={"map sample"}
                                     style={{width: "100%"}}/>
                            </div>}
                    </div>}
                    {props.hospitalTransport && <div className={"text-dark mt-2 ms-2 me-2"} style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "space-around",
                        gap: "0.5rem",
                    }}>
                        <IonIcon icon={carOutline} role="img" style={{fontSize: "40px"}}/>
                        <div style={{lineHeight: "1.2rem"}}>
                            <strong>Hospital transport booked</strong><br/>
                            Be ready by midday
                        </div>
                    </div>}
                    <div className={"card-body"}>
                        <div className={"card-subtitle fw-bold"}>Questions</div>
                        <div className={"card-text text-dark small"}>
                            <ul className={"mb-0"}>
                                <li>Leg swelling</li>
                                <li>Sodium bicarbonate?</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={"card-footer mt-1"} style={{display: "flex"}}>
                    <button className={"btn btn-outline-secondary"}>Did not attend</button>
                    <div style={{flexGrow: 1}}/>
                    <button className={"btn btn-primary"}>Record attendance &gt;</button>
                </div>
            </div>
        </div>
    );
}

export default Item;
