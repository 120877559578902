import React, {useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {archiveMedication, invalidateMedicationsCache} from "../../../../api/medications";
import Confirmation from "../../../../components/ActionWithConfirmation/Confirmation";
import ButtonWithConfirmation from "../../../../components/ActionWithConfirmation/ButtonWithConfirmation";
import {enqueueSnackbar} from "notistack";

function UnarchiveForm(props: { id: string }) {
    const {id} = props;
    const queryClient = useQueryClient();
    const [confirming, setConfirming] = useState<boolean>(false);

    const unarchiveMedicationMutation = useMutation({
        mutationFn: (id: string) => archiveMedication(id, false),
        onSuccess: (_: void) => {
            setConfirming(false);
            invalidateMedicationsCache(queryClient);
            enqueueSnackbar("Medication restored", {variant: "success"});
        },
        retry: 3,
    })

    return (
        <div className="section mt-2">
            <div className="card">
                <div className="card-body">
                    {unarchiveMedicationMutation.isError && <div className="text-center">
                        <h1 className="card-title text-danger">Error</h1>
                        <p className="card-text text-danger">{unarchiveMedicationMutation.error.message}</p>
                        <button className="btn btn-primary" onClick={() => {
                            unarchiveMedicationMutation.mutate(id);
                        }}>
                            Try again
                        </button>
                    </div>}
                    {!unarchiveMedicationMutation.isError && <>
                        {unarchiveMedicationMutation.isPending
                            ?
                            <h1 className={"splash-page mt-2"}>
                                <div className="spinner-border text-primary" style={{width: "36px", height: "36px"}}
                                     role="status"></div>
                            </h1>
                            :
                            <>
                                {confirming
                                    ?
                                    <Confirmation buttonClassName={"btn-primary"}
                                                  areYouSureLabel={"Are you sure you want to restore this medication?"}
                                                  confirmLabel={"Yes, restore it..."}
                                                  confirming={confirming} setConfirming={setConfirming}
                                                  confirmedAction={() => unarchiveMedicationMutation.mutate(id)}/>
                                    :
                                    <div>
                                        <div className={"card-title text-primary text-center"}
                                             style={{textWrap: "balance"}}>
                                            This medication is currently archived. Restore it to make it available
                                            again.
                                        </div>
                                        <ButtonWithConfirmation buttonClassName={"btn-primary"}
                                                                label={"Restore medication..."}
                                                                confirming={confirming} setConfirming={setConfirming}/>
                                    </div>
                                }
                            </>
                        }
                    </>}
                </div>
            </div>
        </div>
    );
}

export default UnarchiveForm;
