import React from "react";

function Loader(props: { className?: string, show: boolean, size?: string }) {
    let size = props.size ? props.size : "60px";

    return (<>
        {props.show && <h1 className={"text-center " + props.className}>
            <div className="spinner-border text-primary" style={{width: size, height: size}}
                 role="status"></div>
        </h1>}
    </>);
}

export default Loader;
