import React from "react";
import {Link, NavLink} from "react-router-dom";
import {getCurrentDate} from "../../../utils/DateTime";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {countPendingScheduledDoses} from "../../../api/medication-schedules";

function MedicationsCard(props: { today: string }): JSX.Element {
    const {today} = props;
    const queryClient = useQueryClient();
    const {
        isLoading: loading,
        isError: isLoadingError,
        data: doseTimes,
        error: loadingError
    } = useQuery({
        queryKey: ["medications", "schedules", today],
        queryFn: () => countPendingScheduledDoses(today),
        staleTime: 10 * (60 * 1000),
        gcTime: 15 * (60 * 1000),
    })

    return (
        <div className="section mt-2">
            <div className="card">
                <div className="card-body">
                    <div style={{display: "flex"}}>
                        <h5 className="card-title m-0" style={{lineHeight: "inherit"}}>Take medications</h5>
                        <div style={{textAlign: "right", flexGrow: 1}}>
                            <NavLink to={"/medications/manage"} className={"btn btn-sm btn-link"}>manage &gt;</NavLink>
                        </div>
                    </div>
                </div>
                {loadingError && <div className="text-center pb-2">
                    <h1 className="cart-title text-danger">Error</h1>
                    <p className="card-text text-danger">{loadingError.message}</p>
                    <button className="btn btn-primary" onClick={() => {
                        queryClient.invalidateQueries({queryKey: ["medications", "schedules", getCurrentDate()]}).then(undefined);
                    }}>Try again
                    </button>
                </div>}
                {loading && !loadingError && <h1 className={"splash-page mt-2"}>
                    <div className="spinner-border text-primary" style={{width: "60px", height: "60px"}}
                         role="status"></div>
                </h1>}
                {!loading && !isLoadingError && <ul className="listview link-listview">
                    {doseTimes && doseTimes.filter(entry => entry[1] > 0).map((entry) =>
                        <li key={entry[0]}>
                            <Link to={"/medications/" + (entry[0])}>
                                {entry[0].indexOf(':') === -1 ? "As needed" : entry[0]}
                                <span className="badge badge-primary">{entry[1]}</span>
                            </Link>
                        </li>)}
                </ul>}
            </div>
        </div>
    );
}

export default MedicationsCard;
