export const dismissModal = (id: string) => {
    let modalElement = document.getElementById(id);
    if (modalElement) {
        // Nasty hack to prevent the full modal from showing while it's being dismissed.
        modalElement.style.visibility = "hidden";
        modalElement.click();
        setTimeout(() => {
            if (modalElement) modalElement.style.visibility = "visible";
        }, 500);
    }
};
