export const ScheduledDoseStatusPending = "pending";
export const ScheduledDoseStatusSkipped = "skipped";
export const ScheduledDoseStatusTaken = "taken";

export interface ScheduleItem {
    date: string;
    time: string;
    medicationId: string;
    medicationName: string;
    medicationUnit: string;
    medicationStrength: number;
    medicationNotes: string;
    status: string;
    timeTaken: string;
    suggestedVariants: MedicationVariantSuggested[];
    variantsTaken: TakenEntry[];
    variantsTakenSummery: string;
}

export interface MedicationSchedule {
    date: string;
    time: string;
    items: ScheduleItem[];
    notes: string;
}

export interface MedicationVariantSuggested {
    form: string;
    canBeHalved: boolean;
    strength: number;
    unit: string;
    minimumWastage: number;
    stock: number;
    suggestedQuantity: number;
}

export interface MedicationVariantTaken {
    strength: number;
    unit: string;
    form: string;
    quantity: number;
    wasted: number;
    stock?: number;
}

export interface TakenEntry {
    medicationId: string;
    newStatus: string;
    variants: MedicationVariantTaken[];
}

export interface MedicationsTaken {
    date: string;
    time: string;
    dateTaken: string;
    timeTaken: string;
    medications: TakenEntry[];
    notes: string;
}

export interface MedicationTakenError {
    date: string;
    time: string;
    medicationId: string;
    error: string;
}

export interface MedicationTakenErrors {
    errors: MedicationTakenError[];
}
