import React from 'react';
import Header from "../../components/Header/Header";

function Measurements(): JSX.Element {
    // const navigate = useNavigate();

    return (
        <>
            <Header title={"Measurements"}
                // rightIcon={addOutline} rightAction={() => navigate("/measurements/add")}
            />
            <div className="section mt-4 text-center">
                Feature in development
            </div>
        </>
    );
}

export default Measurements;
