import React from "react";
import {EntryTakeScheduledMedicationsItem, TakeScheduledMedicationsEntry} from "../../types/Entry";
import {EntryWrapper} from "./Entry";
import {useQuery} from "@tanstack/react-query";
import {fetchMedicationOptionsQueryOptions, optionSingularToPlural} from "../../api/medication-options";

interface MedsForDisplay {
    taken: Map<string, EntryTakeScheduledMedicationsItem[]>;
    skipped: EntryTakeScheduledMedicationsItem[];
    total: number;
}

export default function EntryTakeScheduledMedications(props: {
    showDate: boolean,
    when: string,
    data: TakeScheduledMedicationsEntry
}) {
    const {data} = props;
    const {data: lookups} = useQuery(fetchMedicationOptionsQueryOptions);

    let firstTime = "";
    let displayItems: MedsForDisplay = {
        taken: new Map<string, EntryTakeScheduledMedicationsItem[]>(),
        skipped: [],
        total: 0
    };
    for (const item of data.items as EntryTakeScheduledMedicationsItem[]) {
        if (item.status === "skipped") {
            displayItems.skipped.push(item);
            displayItems.total++;
            continue
        }

        if (item.status !== "taken") {
            console.error("Unexpected entry med status: [" + item.medication_id + "] " + item.status);
        }

        let meds = displayItems.taken.get(item.time_taken);
        if (meds === undefined) {
            meds = [];
            displayItems.taken.set(item.time_taken, meds);
            if (firstTime === "") {
                firstTime = item.time_taken;
            }
        }

        meds.push(item);
        displayItems.total++;
    }

    return (
        <EntryWrapper date={props.showDate ? props.when : ""}>
            <div className="card">
                <div className="card-body ps-0 pe-0 pb-0">
                    <div className="ms-2 me-2" style={{display: "flex", gap: "0.5rem", alignItems: "center"}}>
                        <img src={"/temp/pill-duotone.svg"} alt="medication dose"
                             style={{width: "40px", height: "40px"}}/>
                        <div style={{flexGrow: 1}}>
                            <h5 className="card-title">Scheduled
                                medication{displayItems.total > 1 ? "s" : ""} taken</h5>
                            <h6 className="card-subtitle">{data.time}</h6>
                        </div>
                    </div>
                    <div className="timeline timed mt-2"
                         style={{paddingBottom: 0, paddingTop: "8px", borderTop: "1px solid #ccc"}}>
                        {displayItems.taken.size > 0 && Array.from(displayItems.taken.entries()).map(([timeTaken, meds]) =>
                            <div key={timeTaken} className="item pb-1 text-dark mb-0">
                                <div className="time text-dark"
                                     style={{
                                         textAlign: "right",
                                         marginTop: timeTaken === firstTime ? "-3px" : "4px",
                                     }}>{timeTaken === firstTime && <>Taken at<br/></>}{timeTaken}</div>
                                <div className="dot bg-success" style={{marginTop: "4px"}}></div>
                                <div className="content">
                                    <table>
                                        <tbody>
                                        {meds.map(med =>
                                            <tr key={med.medication_id}>
                                                <td style={{
                                                    textAlign: "right",
                                                    paddingRight: "0.5rem",
                                                    minWidth: "4.5rem",
                                                }}>{med.strength} {lookups ? optionSingularToPlural(lookups.units, med.strength, med.unit) : med.unit}</td>
                                                <td>{med.name}</td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                        {displayItems.skipped.length > 0 &&
                            <div className="item pb-1 text-dark mb-0">
                                <div className="time text-dark"
                                     style={{
                                         textAlign: "right",
                                         marginTop: "4px",
                                     }}>Skipped
                                </div>
                                <div className="dot bg-warning" style={{marginTop: "4px"}}></div>
                                <div className="content">
                                    <table>
                                        <tbody>
                                        {displayItems.skipped.map(med =>
                                            <tr key={med.medication_id}>
                                                <td style={{
                                                    textAlign: "right",
                                                    paddingRight: "0.5rem",
                                                    minWidth: "4.5rem",
                                                }}>{med.strength} {lookups ? optionSingularToPlural(lookups.units, med.strength, med.unit) : med.unit}</td>
                                                <td>{med.name}</td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        {data.notes !== "" &&
                            <div className="item pb-1">
                                <div className="time" style={{textAlign: "right"}}>Notes</div>
                                <div className="content" style={{
                                    fontSize: "0.8rem",
                                    lineHeight: "1.1rem",
                                    paddingTop: "2px",
                                }}>{data.notes}</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </EntryWrapper>
    );
}
