import React from "react";
import BottomMenuItem from "./BottomMenuItem";
import {
    addCircleOutline,
    analyticsOutline,
    calendarOutline,
    listOutline,
    medicalOutline,
    personOutline
} from "ionicons/icons";
import "./BottomMenu.css";
import BottomMenuActionItem from "./BottomMenuActionItem";

function BottomMenu(props: any) {
    const {currentItem} = props;

    return (
        <div className="appBottomMenu">
            <BottomMenuItem url={"/entries"} active={currentItem === "entries"}
                            icon={listOutline} label={"My EPR"}/>
            <BottomMenuItem url={"/measurements"} active={currentItem === "measurements"}
                            icon={analyticsOutline} label={"Measurements"}/>
            <BottomMenuItem url={"/medications"} active={currentItem === "medications"}
                            icon={medicalOutline} label={"Medications"}/>
            <BottomMenuItem url={"/appointments"} active={currentItem === "appointments"}
                            icon={calendarOutline} label={"Appointments"}/>
            <BottomMenuItem url={"/account"} active={currentItem === "account"}
                            icon={personOutline} label={"Account"}/>
            <BottomMenuActionItem url={"/entries/add"} active={currentItem === "entries/add"}
                                  icon={addCircleOutline} large={true}/>
        </div>
    );
}

export default BottomMenu;
