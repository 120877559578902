import Loader from "./Loader";
import LoadingError from "./LoadingError";
import {PropsWithChildren} from "react";

function LoadingWrapper(props: PropsWithChildren<{
    className?: string,
    loaderSize?: string,
    isLoading: boolean,
    hasError: boolean,
    errorMessage: string,
    onRetry: () => void,
}>): JSX.Element {
    if (props.hasError) {
        return <LoadingError className={"mt-2 " + props.className} hasError={props.hasError}
                             errorMessage={props.errorMessage}
                             onRetry={props.onRetry}/>;
    }

    if (props.isLoading) {
        return <Loader className={"mt-2 " + props.className} show={true} size={props.loaderSize}/>;
    }

    return <>{props.children}</>;
}

export default LoadingWrapper;
