import {useNavigate} from "react-router-dom";
import {analyticsOutline, calendarOutline, medicalOutline} from "ionicons/icons";
import BigButton from "../../components/Buttons/BigButton";

function IntroScreen(): JSX.Element {
    const navigate = useNavigate();

    return (<>
        <div className="section mt-4 text-center">
            <h2 className="text-dark" style={{textWrap: "balance"}}>
                Welcome to your personal Electronic Patient Record
            </h2>
            <p className="text-secondary mt-4" style={{textWrap: "balance"}}>
                To get started, add a measurement, medication, or appointment using the buttons below.
            </p>
        </div>
        <div className="section mt-4">
            <div className="row">
                <div className="col-4">
                    <BigButton icon={analyticsOutline} label={"Measurement"}
                               onClick={() => navigate("/measurements/edit/new")}/>
                </div>
                <div className="col-4">
                    <BigButton icon={medicalOutline} label={"Medication"}
                               onClick={() => navigate("/medications/manage/edit/new")}/>
                </div>
                <div className="col-4">
                    <BigButton icon={calendarOutline} label={"Appointment"}
                               onClick={() => navigate("/appointments/edit/new")}/>
                </div>
            </div>
        </div>
    </>);
}

export default IntroScreen;
