import {AxiosRequestConfig} from "axios";
import {getUserToken, removeUserToken, setPostAuthDest} from "../auth/store";
import {ResponseError} from "../utils/ResponseError";

export const baseUrl = process.env.NODE_ENV === "development"
    ?
    "http://127.0.0.1:43001"
    :
    "https://api.myepr.app";

export const getAxiosRequestOptions = (): AxiosRequestConfig => {
    let token = getUserToken();
    if (token) {
        return {
            headers: {
                "Authorization": "Bearer " + token,
            },
        }
    } else {
        console.log("No token found in local storage");
    }
    return {};
};

export const handleAxiosError = (msg: string): ((err: any) => Promise<any>) => (err: any): Promise<any> => {
    if (err.response) {
        switch (err.response.status) {
            case 401:
                // Unauthorized
                console.log("Unauthorized");
                removeUserToken();
                setPostAuthDest(window.location.pathname);
                window.location.href = "/login/google";
                return Promise.reject("Unauthorized (401)");
            case 403:
                // Forbidden
                console.log("Forbidden");
                removeUserToken();
                setPostAuthDest(window.location.pathname);
                window.location.href = "/login/google";
                return Promise.reject("Unauthorized (403)");
            case 404:
            case 500:
            default:
                break;
        }
    } else if (err.request) {
        console.log(err.request);
    } else {
        console.log('Error', err.message);
    }
    throw new ResponseError(msg, err.response);
}