import React from "react";
import TakeTimeTabs from "./TakeTimeTabs";
import Actions from "../Actions";

function TakeSection(props: { date: string, time: string }): JSX.Element {
    const [hasMedications, setHasMedications] = React.useState(true);

    return (<>
        <Actions withAdd={!hasMedications} addOnly={!hasMedications}/>
        <TakeTimeTabs selectedDate={props.date} selectedTime={props.time} setHasMedications={setHasMedications}/>
    </>);
}

export default TakeSection;
