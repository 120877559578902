import React, {useEffect, useState} from 'react';
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {fetchMedicationsQueryOptions} from "../../../api/medications";
import Medication from "../../../types/Medication";
import Item from "./Item";
import {add, remove} from "ionicons/icons";
import HeaderWithBackButton from "../../../components/Header/HeaderWithBackButton";
import {IonIcon} from "@ionic/react";
import Actions from "../Actions";
import LoadingWrapper from "../../../components/Loader/LoadingWrapper";
import PullToRefreshWrapper from "../../../components/PullToRefreshWrapper/PullToRefreshWrapper";

function ManageMedications(): JSX.Element {
    const queryClient = useQueryClient();
    const {
        isLoading: loading,
        isError: isLoadingError,
        data: medications,
        error: loadingError,
        refetch,
    } = useQuery(fetchMedicationsQueryOptions);
    const [activeCount, setActiveCount] = useState(0);
    const [archivedCount, setArchivedCount] = useState(0);
    const [archivedOpen, setArchivedOpen] = useState(false);

    useEffect(() => {
        let activeNum = 0;
        let archivedNum = 0;
        if (medications) {
            for (const medication of medications || []) {
                if (medication.archived) {
                    archivedNum++;
                } else {
                    activeNum++;
                }
            }
        }
        setActiveCount(activeNum);
        setArchivedCount(archivedNum);
    }, [medications]);

    return (<>
        <HeaderWithBackButton title={"Manage medications"} helpId={"manage-medications"}/>
        <LoadingWrapper isLoading={loading} hasError={isLoadingError} errorMessage={loadingError?.message ?? ""}
                        onRetry={() => queryClient.invalidateQueries({queryKey: ["medications"]})}>
            <PullToRefreshWrapper onRefresh={refetch}><>
                <Actions withAdd={true} addOnly={!medications || medications.length === 0}/>
                {medications && medications.length > 0 && <>
                    {activeCount > 0 && <>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div className="listview-title" style={{flexGrow: 1}}>Active medications</div>
                            <div className="badge badge-primary" style={{marginRight: "16px"}}>{activeCount}</div>
                        </div>
                        <ul className="listview link-listview mb-2">
                            {medications.filter((m) => !m.archived).map((medication: Medication) =>
                                <Item key={medication.medicationId} medication={medication}/>)}
                        </ul>
                    </>}
                    {archivedCount > 0 && <>
                        <div onClick={() => setArchivedOpen(!archivedOpen)} className={archivedOpen ? "" : "mb-2"}
                             style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <IonIcon icon={archivedOpen ? remove : add} className={"text-primary"}
                                     style={{marginLeft: "8px", fontSize: "1.35em"}}/>
                            <div className="listview-title" style={{flexGrow: 1, paddingLeft: "0"}}>Archived
                                medications
                            </div>
                            <div className="badge badge-secondary"
                                 style={{marginRight: "8px"}}>{archivedCount}</div>
                        </div>
                        {archivedOpen &&
                            <ul className={"listview link-listview mb-2"}>
                                {medications.filter((m) => m.archived).map((medication: Medication) =>
                                    <Item key={medication.medicationId} medication={medication} disabled={true}/>)}
                            </ul>
                        }
                    </>}
                </>}
            </>
            </PullToRefreshWrapper>
        </LoadingWrapper>
    </>);
}

export default ManageMedications;
