import React from 'react';
import Header from "../../components/Header/Header";
import {addOutline, calendarOutline} from "ionicons/icons";
import {useNavigate} from "react-router-dom";
import Item from "./Item";

function Appointments(): JSX.Element {
    const navigate = useNavigate();
    // const queryClient = useQueryClient();
    // const {
    //     isLoading: loading,
    //     isError: isLoadingError,
    //     data: appointments,
    //     error: loadingError
    // } = useQuery(fetchAppointmentsQueryOptions);

    return (<>
        <Header title={"Appointments"}
                leftIcon={calendarOutline} leftAction={() => navigate("/appointments/ical")}
                rightIcon={addOutline} rightAction={() => navigate("/appointments/edit/new")}/>
        <div className={"section mt-2"}>
            <Item hospitalTransport={false}/>
            <Item hospitalTransport={true}/>
        </div>
    </>);
}

export default Appointments;
