import {IonIcon} from "@ionic/react";
import {chevronForwardOutline} from "ionicons/icons";
import React, {useEffect, useState} from "react";
import randomString from "../../../utils/RandomString";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {fetchPendingTakeNotesOptionsFunc, setPendingTakeNotes} from "../../../api/medication-pending-take";

export const TakeNotes = (props: { date: string, time: string, editable: boolean, notes: string }): JSX.Element => {
    const queryClient = useQueryClient();
    const modalId = randomString(16);
    const [notesBeingEdited, setNotesBeingEdited] = useState<string>("");
    const {data: currentNotes} = useQuery(fetchPendingTakeNotesOptionsFunc(props.date, props.time, props.notes));

    useEffect(() => {
        if (currentNotes === undefined) {
            setNotesBeingEdited("");
        } else {
            setNotesBeingEdited(currentNotes);
        }
    }, [currentNotes]);

    return (<>
        <div className="wide-block pt-2 pb-2 pointer" data-bs-toggle={"modal"} data-bs-target={"#" + modalId}>
            {props.editable && <div className={"mb-2"} style={{display: "flex", justifyContent: "center"}}>
                <h4 className={"mb-0"} style={{fontWeight: "bold", flexGrow: 1}}>Notes</h4>
                <div className={"mb-0"} style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <div className="text-muted" style={{paddingRight: "0.4rem"}}>Edit</div>
                    <IonIcon icon={chevronForwardOutline} style={{paddingTop: "1px"}}
                             className={"md hydrated text-muted"}/>
                </div>
            </div>}
            <div
                className={"mb-0" + (currentNotes === "" ? " text-muted" : " text-dark")}
                style={{whiteSpace: "pre-line", lineHeight: "1.1rem"}}>
                {currentNotes === ""
                    ?
                    <p className="card-text" style={{fontStyle: "italic"}}>No notes.</p>
                    :
                    <p className="card-text">{currentNotes}</p>}
            </div>
        </div>
        <div className="modal fade action-sheet" id={modalId} tabIndex={-1}
             aria-modal="true" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Edit notes</h5>
                    </div>
                    <div className="modal-body">
                        <div className="action-sheet-content" style={{paddingTop: "0"}}>
                            <form>
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                            <textarea className="form-control mt-1 mb-2"
                                                      placeholder={""} value={notesBeingEdited}
                                                      style={{display: "block", height: window.innerHeight / 4}}
                                                      onChange={(e) => setNotesBeingEdited(e.target.value)}/>
                                    </div>
                                    <div style={{display: "flex", gap: "1rem", justifyContent: "center"}}>
                                        <button className={"btn btn-secondary"} data-bs-dismiss={"modal"}
                                                onClick={(e) => e.preventDefault()}>
                                            Cancel
                                        </button>
                                        <button className={"btn btn-primary"} data-bs-dismiss={"modal"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setPendingTakeNotes(queryClient, props.date, props.time, notesBeingEdited);
                                                }}>
                                            Set notes
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
};
