import axios from "axios";
import {baseUrl, getAxiosRequestOptions, handleAxiosError} from "./base";
import {QueryClient} from "@tanstack/react-query";
import {createMedicationVariant, MedicationVariant} from "../types/MedicationVariants";

const queryClient = new QueryClient();

export const fetchMedicationVariantsQueryOptionsFunc = (medicationId: string, suggestForDose: number, delayed?: boolean) => ({
    queryKey: ["medication", medicationId, "variants"],
    queryFn: () => fetchMedicationVariants(queryClient, medicationId, suggestForDose),
    staleTime: 10 * (60 * 1000),
    gcTime: 15 * (60 * 1000),
    refetchOnWindowFocus: !delayed,
    enabled: !delayed,
})

export const fetchMedicationVariants = async (queryClient: QueryClient, medicationId: string, suggestForDose: number): Promise<MedicationVariant[]> => {
    return axios.post(
        `${baseUrl}/medications/variants/list`,
        {"medicationId": medicationId, "suggestForDose": suggestForDose},
        getAxiosRequestOptions(),
    ).then((res) => {
        if (!res.data.variants) {
            return [];
        }
        let variants: MedicationVariant[] = res.data.variants;
        for (let i = 0; i < variants.length; i++) {
            variants[i] = createMedicationVariant(variants[i]);
        }
        queryClient.setQueryData(["medications", medicationId, "variants"], variants);
        return variants;
    }).catch(handleAxiosError("Failed to load medication variants"));
};

export const saveMedicationVariant = async (
    medicationId: string,
    medicationName: string,
    medicationUnit: string,
    strength: number,
    form: string,
    canBeHalved: boolean,
    minimumWastage: number,
    stock: number,
): Promise<MedicationVariant> => {
    if (medicationId === "" || strength <= 0 || form === "" || minimumWastage < 0 || stock < 0) {
        throw new Error("All fields are required");
    }

    let body: MedicationVariant = createMedicationVariant({
        medicationId: medicationId,
        medicationName: medicationName,
        medicationUnit: medicationUnit,
        strength: strength,
        form: form,
        canBeHalved: canBeHalved,
        minimumWastage: minimumWastage,
        stock: stock,
    });

    return axios.post(
        `${baseUrl}/medications/variants/write`,
        body,
        getAxiosRequestOptions(),
    )
        .then((res): Promise<MedicationVariant> => Promise.resolve(res.data))
        .catch(handleAxiosError("Failed to save medication variant"));
};

export const deleteMedicationVariant = async (medicationId: string, strength: number, form: string): Promise<void> => {
    let body = {
        medicationId: medicationId,
        strength: strength,
        form: form,
    };

    return axios.post(
        `${baseUrl}/medications/variants/delete`,
        body,
        getAxiosRequestOptions(),
    )
        .then((res): Promise<void> => Promise.resolve())
        .catch(handleAxiosError("Failed to delete medication variant"));
};
