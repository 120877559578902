import React from "react";
import "./Header.css";
import {chevronBackOutline} from "ionicons/icons";
import {useNavigate} from "react-router-dom";
import Header from "./Header";

function HeaderWithBackButton(props: {
    title: string,
    rightIcon?: any,
    rightAction?: any,
    helpId?: string,
}) {
    const navigate = useNavigate();

    return (
        <Header title={props.title}
                leftIcon={chevronBackOutline} leftAction={() => navigate(-1)}
                rightIcon={props.rightIcon} rightAction={props.rightAction}
                helpId={props.helpId}/>
    );
}

export default HeaderWithBackButton;
