import React from "react";
import {useParams} from "react-router-dom";
import DetailsForm from "./DetailsForm";
import Schedule from "./Schedule/Schedule";
import ArchiveForm from "./ArchiveForm";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {fetchMedication} from "../../../../api/medications";
import HeaderWithBackButton from "../../../../components/Header/HeaderWithBackButton";
import UnarchiveForm from "./UnarchiveForm";
import Variants from "./Variants/Variants";

function EditMedication(): JSX.Element {
    const {id} = useParams<{ id: string }>();
    const queryClient = useQueryClient();
    const {
        isLoading,
        isError: isLoadingError,
        data: medication,
        error: loadingError
    } = useQuery({
        queryKey: ["medications", id ? id : "new"],
        queryFn: () => fetchMedication(queryClient, id ? id : "new"),
        staleTime: 10 * (60 * 1000),
        gcTime: 15 * (60 * 1000),
    });

    return (
        <>
            <HeaderWithBackButton title={(id === "new" ? "Add" : "Edit") + " Medication"}/>
            {isLoadingError && <div className="text-center mt-5">
                <h1 className="cart-title text-danger">Error</h1>
                <p className="card-text text-danger">{loadingError.message}</p>
                <button className="btn btn-primary"
                        onClick={() => queryClient.invalidateQueries({queryKey: ["medications", id ? id : "new"]})}>Try
                    again
                </button>
            </div>}
            {isLoading && !isLoadingError && <h1 className={"splash-page mt-5"}>
                <div className="spinner-border text-primary" style={{width: "60px", height: "60px"}}
                     role="status"></div>
            </h1>}
            {!isLoading && !isLoadingError && medication && <>
                {id && id !== "new" && medication.archived && <>
                    <UnarchiveForm id={id}/>
                    <hr/>
                </>}
                <DetailsForm id={id ? id : "new"} medication={medication!}/>
                {id && id !== "new" && medication && <>
                    <Variants medication={medication}/>
                    <Schedule medicationId={medication.medicationId!}/>
                    {!medication.archived && <>
                        <hr/>
                        <ArchiveForm id={id}/>
                    </>}
                </>}
            </>}
            <div className={"mt-2"}></div>
        </>
    );
}

export default EditMedication;
