import HeaderWithBackButton from "../../../components/Header/HeaderWithBackButton";
import React, {useState} from "react";
import {helpCircleOutline} from "ionicons/icons";
import Medication from "../../../types/Medication";
import {
    MedicationVariantStockUpdates,
    StockUpdateFailure,
    StockUpdateFailures
} from "../../../types/MedicationVariants";
import HelpCard from "../../../components/HelpPanel/HelpCard";
import axios from "axios";
import {NavLink, useNavigate} from "react-router-dom";
import Item from "./Item";
import {baseUrl} from "../../../api/base";
import Wizard from "./Wizard/Wizard";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {fetchMedicationsQueryOptions, invalidateMedicationsCache} from "../../../api/medications";

function AllItems(): JSX.Element {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {
        isLoading: loading,
        isError: isLoadingError,
        data: medications,
        error: loadingError
    } = useQuery(fetchMedicationsQueryOptions);
    const [changes, setChanges] = useState<Map<string, number>>(new Map<string, number>());
    const [saving, showSaving] = useState<boolean>(false);
    const [savingError, setSavingError] = useState<string>("");
    const [failures, setFailures] = useState<Map<string, string>>(new Map<string, string>());
    const [usingWizard, setUsingWizard] = useState<boolean>(false);
    const [hideHelp, setHideHelp] = useState<boolean>(true);

    const updateChange = (id: string, change: number) => {
        const newChanges = new Map(changes);
        if (change === 0) {
            newChanges.delete(id);
        } else {
            newChanges.set(id, change);
        }
        setChanges(newChanges);
    };

    const applyChanges = () => {
        showSaving(true);
        setSavingError("");
        setFailures(new Map<string, string>());

        if (changes.size === 0) {
            // Don't submit if there were no changes.
            navigate("/medications");
            return;
        }

        let body: MedicationVariantStockUpdates = {modifications: []};
        changes.forEach((value, key) => {
            body.modifications.push({medicationId: key, stockChange: value});
        });

        axios.post(`${baseUrl}/medications/stock/modify`, body).then((res) => {
            invalidateMedicationsCache(queryClient);
            showSaving(false);
            let unsavedChanges: Map<string, number> = new Map<string, number>();
            let response: StockUpdateFailures = res.data;
            if (response.failures) {
                response.failures.forEach((failure: StockUpdateFailure): void => {
                    unsavedChanges.set(failure.medication.medicationId!, changes.get(failure.medication.medicationId!)!);
                    failures.set(failure.medication.medicationId!, failure.reason);
                });
                if (failures.size > 0) {
                    setChanges(unsavedChanges);
                    setFailures(failures);
                    return;
                }
            }
            navigate("/medications");
        }).catch((err) => {
            console.log(err);
            showSaving(false);
            setSavingError("Failed to apply stock changes.");
        });
    };

    return <>
        <HeaderWithBackButton title={"Update Medication Stock"}
                              rightIcon={usingWizard ? null : helpCircleOutline}
                              rightAction={() => setHideHelp(!hideHelp)}/>
        {!usingWizard &&
            <div className={"mt-1"}>
                <HelpCard title={"Bulk update medication stock"}
                          hidden={hideHelp} setHiddenFn={setHideHelp}
                          hidePreferenceKey={"medications"} hidePreferenceSubKey={"hide-bulk-stock-update-help"}>
                    <p className="card-text">
                        Use this page to process receipt of a repeat prescription.
                    </p>
                    <p>
                        The wizard (started using the button below) will guide you through the process of updating
                        each medication in turn. Changes are applied to the strok of each medication immediately.
                    </p>
                    <p className="card-text">
                        Alternatively, for each item in the list use the buttons to add the quantity received.
                        This is also a great time to check existing stock for expiry and damage, and reduce the
                        stock levels accordingly.
                    </p>
                    <p className="card-text">
                        Don't forget to click the "Apply stock changes..." button at the bottom of the page to
                        apply the changes.
                    </p>
                </HelpCard>
            </div>}
        {isLoadingError && <div className="section full mb-2">
            <div className="card" style={{borderRadius: "0"}}>
                <div className="card-body text-center">
                    <h1 className="cart-title text-danger">Error</h1>
                    <p className="card-text text-danger">{loadingError.message}</p>
                    <button className="btn btn-primary" onClick={() => {
                        setTimeout(
                            () => queryClient.invalidateQueries({queryKey: ["medications"]}), 500);
                    }}>Try again
                    </button>
                </div>
            </div>
        </div>}
        {loading && <h1 className={"splash-page mt-5"}>
            <div className="spinner-border text-primary" style={{width: "60px", height: "60px"}}
                 role="status"></div>
        </h1>}
        {!loading && !isLoadingError && medications!.length === 0 && <div className={"text-center mt-5 mb-5"}>
            <p><NavLink to={"/medications"}>Go to the main medications list to add your first medication.</NavLink></p>
        </div>}
        {!loading && !isLoadingError && medications!.length > 0 && <>
            {failures.size > 0 && <div className="section full mb-2">
                <div className="card" style={{borderRadius: "0"}}>
                    <div className="card-body text-center">
                        <h1 className="cart-title text-danger">Error</h1>
                        <p className="card-text text-dark">Some changes could not be applied. The errors are listed
                            below so you can make corrections and apply the changes again.</p>
                    </div>
                </div>
            </div>}
            {usingWizard && <Wizard medications={medications!}/>}
            {!usingWizard && <>
                <div className="section mt-2 mb-2">
                    <div className="card">
                        <div className="card-body">
                            <button type="button" className="btn btn-primary btn-block"
                                    title={"Start the stock update wizard..."}
                                    onClick={() => setUsingWizard(true)}>
                                Start the stock update wizard...
                            </button>
                        </div>
                    </div>
                </div>
                <ul className="listview mb-2">
                    {medications!.map((medication: Medication) =>
                        <Item key={medication.medicationId} medication={medication}
                              setFunction={updateChange}
                              applyFailed={failures.size > 0}
                              reason={failures.get(medication.medicationId!)!}/>
                    )}
                </ul>
                <div className="section full mt-2 mb-2">
                    <div className="card" style={{borderRadius: "0"}}>
                        <div className="card-body text-center">
                            {savingError.length > 0 && <>
                                <h1 className="cart-title text-danger">Error</h1>
                                <p className="card-text text-danger">{savingError}</p>
                                <button className="btn btn-primary" onClick={() => {
                                    showSaving(true);
                                    setSavingError("");
                                    setTimeout(applyChanges, 500);
                                }}>Try again
                                </button>
                            </>}
                            {saving && savingError.length === 0 &&
                                <div className="spinner-border text-primary" style={{width: "60px", height: "60px"}}
                                     role="status"></div>}
                            {!saving && savingError.length === 0 &&
                                <button type="button" className="btn btn-primary btn-block"
                                        disabled={changes.size === 0}
                                        title={changes.size === 0 ? "No changes to apply" : "Apply changes..."}
                                        onClick={() => applyChanges()}>
                                    Apply stock changes...
                                </button>}
                        </div>
                    </div>
                </div>
            </>}
        </>}
    </>;
}

export default AllItems;
