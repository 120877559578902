import {chevronBackOutline} from "ionicons/icons";
import {useNavigate} from "react-router-dom";
import Header from "../../../components/Header/Header";
import React from "react";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {fetchSummary} from "../../../api/medication-summary";

function Summary(): JSX.Element {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {
        isLoading: loading,
        isError: isLoadingError,
        data: summary,
        error: loadingError
    } = useQuery({
        queryKey: ["medications", "summary"],
        queryFn: fetchSummary,
        staleTime: 10 * (60 * 1000),
        gcTime: 15 * (60 * 1000),
    })

    return <>
        <Header title={"Medication Summary"}
                leftIcon={chevronBackOutline} leftAction={() => navigate(-1)}/>
        {isLoadingError && <div className="text-center">
            <h1 className="cart-title text-danger">Error</h1>
            <p className="card-text text-danger">{loadingError.message}</p>
            <button className="btn btn-primary" onClick={() => {
                queryClient.invalidateQueries({queryKey: ["summary"]}).then(undefined);
            }}>Try again
            </button>
        </div>}
        {loading && !loadingError && <h1 className={"splash-page mt-2"}>
            <div className="spinner-border text-primary" style={{width: "60px", height: "60px"}}
                 role="status"></div>
        </h1>}
        {!loading && !isLoadingError && summary && summary.weekly! && <div className="section mb-2">
            <div className="section-title">Weekly schedule</div>
            {summary.weekly.map((weekly, _) => <div key={weekly.title} className="card mb-2">
                <div className="card-body p-0">
                    <h5 className="card-title text-white bg-secondary pt-1 pb-1 ps-1 pe-1"
                        style={{
                            margin: "0",
                            borderTopLeftRadius: "0.5rem",
                            borderTopRightRadius: "0.5rem"
                        }}>{weekly.title}</h5>
                    <div className="timeline timed">
                        {weekly.doses.map((dose, _) => <div key={dose.time} className="item">
                            <span className="time text-dark">{dose.time}</span>
                            <div className="dot bg-secondary"></div>
                            <div className="content">
                                {dose.medications.map((medication, _) =>
                                    <h4 key={medication.name} className="text">{medication.name} <span
                                        className={"text-muted"}>{medication.dose} {medication.unit}</span>
                                    </h4>)}
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>)}
        </div>}
        {!loading && !isLoadingError && summary && summary.interval! && <div className="section mb-2">
            <div className="section-title">Regular intervals</div>
            {summary.interval.map((interval, _) => <div key={interval.title} className="card mb-2">
                <div className="card-body p-0">
                    <h5 className="card-title text-white bg-secondary pt-1 pb-1 ps-1 pe-1"
                        style={{
                            margin: "0",
                            borderTopLeftRadius: "0.5rem",
                            borderTopRightRadius: "0.5rem"
                        }}>{interval.title}</h5>
                    <div className="timeline timed">
                        {interval.doses.map((dose, _) => <div key={dose.time} className="item">
                            <span className="time text-dark">{dose.time}</span>
                            <div className="dot bg-secondary"></div>
                            <div className="content">
                                {dose.medications.map((medication, _) =>
                                    <h4 key={medication.name} className="text">{medication.name} <span
                                        className={"text-muted"}>{medication.dose} {medication.unit}</span>
                                    </h4>)}
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>)}
        </div>}
        {!loading && !isLoadingError && summary && summary.asNeeded! && <div className="section mb-2">
            <div className="section-title">As needed</div>
            <div className="card mb-2">
                <div className="card-body p-0">
                    <div className="timeline timed">
                        <div className="item">
                            <span className="time text-dark"></span>
                            <div className="content">
                                {summary.asNeeded.map((medicationName, _) =>
                                    <h4 key={medicationName} className="text">{medicationName}</h4>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>}
    </>;
}

export default Summary;
