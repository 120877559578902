import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Medication, {MedicationFrequencyTypeAsNeeded} from "../../../../types/Medication";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {invalidateMedicationsCache, saveMedication} from "../../../../api/medications";
import StringField from "../../../../components/Fields/StringField";
import LookupField from "../../../../components/Fields/LookupField";
import TextareaField from "../../../../components/Fields/TextareaField";

function DetailsForm(props: { id: string, medication: Medication }): JSX.Element {
    const {id, medication} = props;
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [unit, setUnit] = useState<string>("");
    const [notes, setNotes] = useState<string>("");

    const saveMedicationMutation = useMutation({
        mutationFn: () => saveMedication(queryClient, id, name, unit, notes, medication.frequencyType ? medication.frequencyType : MedicationFrequencyTypeAsNeeded, medication.frequencyDetail!, medication.archived),
        onSuccess: (data: Medication) => {
            setIsDirty(false);
            invalidateMedicationsCache(queryClient);
            if (id === "new") {
                navigate("/medications/manage/edit/" + data.medicationId, {replace: true});
            }
        },
        retry: 3,
    });

    useEffect(() => {
        if (medication) {
            setName(medication.name);
            setUnit(medication.unit);
            setNotes(medication.notes ? medication.notes : "");
        }
    }, [medication]);

    return (
        <div className="section mt-2">
            <div className="card">
                <div className="card-body">
                    <div style={{display: "flex", gap: "1rem"}}>
                        <StringField label={"Name"} value={name}
                                     isDirty={isDirty} setIsDirty={setIsDirty}
                                     setFunction={setName}/>
                        <LookupField type={"unit"} label={"Unit"} value={unit}
                                     isDirty={isDirty} setIsDirty={setIsDirty}
                                     helpId={"medications-unit"}
                                     setFunction={setUnit}/>
                    </div>
                    <TextareaField label={"Purpose / notes"} value={notes} setFunction={setNotes}
                                   isDirty={isDirty} setIsDirty={setIsDirty}/>
                    <div className={"mt-1"}></div>
                    {saveMedicationMutation.isError && <div className="text-center">
                        <p className="card-text text-danger">{saveMedicationMutation.error.message}</p>
                        <button className="btn btn-primary" onClick={() => {
                            saveMedicationMutation.reset();
                            saveMedicationMutation.mutate();
                        }}>
                            Try again
                        </button>
                    </div>}
                    {saveMedicationMutation.isPending && !saveMedicationMutation.isError &&
                        <h1 className={"splash-page mt-2"}>
                            <div className="spinner-border text-primary" style={{width: "40px", height: "40px"}}
                                 role="status"></div>
                        </h1>}
                    {!saveMedicationMutation.isPending && !saveMedicationMutation.isError && isDirty &&
                        <button type="button" className="btn btn-primary btn-block"
                                onClick={() => saveMedicationMutation.mutate()}>
                            {id === "new" ? "Create medication" : "Save changes"}...
                        </button>}
                </div>
            </div>
        </div>
    );
}

export default DetailsForm;
