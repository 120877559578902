import React, {useEffect, useState} from "react";
import randomString from "../../utils/RandomString";
import HelpButton from "../HelpPanel/HelpButton";

function FloatField(props: {
    children?: JSX.Element,
    style?: React.CSSProperties,
    label: string | JSX.Element,
    labelStyle?: React.CSSProperties,
    value: number,
    setFunction: (value: number) => void,
    inputStyle?: React.CSSProperties,
    helpId?: string,
    withPopups?: boolean,
    isDirty?: boolean,
    setIsDirty?: (isDirty: boolean) => void,
}): JSX.Element {
    const randomId = randomString(8)
    const [internalValue, setInternalValue] = useState<string>(props.value.toString());
    const [hasError, setHasError] = useState<boolean>(false);

    useEffect(() => {
        setInternalValue(props.value.toString());
    }, [props.value]);

    const updateFloatValue = (newValue: string) => {
        setInternalValue(newValue);
        if (props.setIsDirty) {
            props.setIsDirty(true);
        }
        let newFloatValue = parseFloat(newValue);
        if (isNaN(newFloatValue)) {
            setHasError(true);
            return;
        }
        setHasError(false);
        if (!newValue.endsWith(".")) {
            props.setFunction(newFloatValue);
        }
    }

    return (
        <div className="form-group basic input-wrapper"
             style={{
                 position: "relative",
                 paddingLeft: "0px",
                 paddingRight: "0px",
                 ...props.style,
                 border: hasError ? "1px dashed #ff6961" : "inherit",
             }}>
            {props.withPopups && props.helpId && <HelpButton id={props.helpId}/>}
            <label className="label" htmlFor={randomId}
                   style={{textWrap: "balance", ...props.labelStyle}}>{props.label}</label>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "0.25rem"}}>
                <input type="number" min="0" step="any" inputMode="decimal"
                       className="form-control" style={{paddingRight: 0, ...props.inputStyle}} id={randomId}
                       value={internalValue}
                       onFocus={(e) => e.target.select()}
                       onChange={(e) => updateFloatValue(e.target.value)}/>
                {props.children}
            </div>
        </div>
    );
}

export default FloatField;
