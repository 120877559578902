import axios from "axios";
import {baseUrl, getAxiosRequestOptions, handleAxiosError} from "./base";
import Medication, {createMedication} from "../types/Medication";
import {QueryClient} from "@tanstack/react-query";

const queryClient = new QueryClient();

export const fetchMedicationsQueryOptions = {
    queryKey: ["medications", "list"],
    queryFn: () => fetchMedications(queryClient, false),
    staleTime: 10 * (60 * 1000),
    gcTime: 15 * (60 * 1000),
};

export const fetchMedications = async (queryClient: QueryClient, withVariants: boolean): Promise<Medication[]> => {
    return axios.post(
        `${baseUrl}/medications/list`,
        {"withVariants": withVariants},
        getAxiosRequestOptions(),
    ).then((res) => {
        if (!res.data.medications) {
            return [];
        }
        const medications: Medication[] = res.data.medications;
        medications.forEach((medication) => {
            queryClient.setQueryData(["medications", medication.medicationId], medication);
        });
        return medications;
    }).catch(handleAxiosError("Failed to load medications"));
};

export const fetchMedication = async (queryClient: QueryClient, medicationId: string): Promise<Medication> => {
    if (medicationId === "new") {
        return Promise.resolve(createMedication({}));
    } else {
        return axios.post(
            `${baseUrl}/medications/read`,
            {medicationId: medicationId},
            getAxiosRequestOptions(),
        )
            .then((res) => createMedication(res.data))
            .catch(handleAxiosError("Failed to get medication details"));
    }
};

export const invalidateMedicationsCache = (queryClient: QueryClient): void => {
    setTimeout(() => {
        // Invalidate all cached medication queries.
        queryClient.invalidateQueries({queryKey: ["medications"]})
            .then(() => queryClient.invalidateQueries({queryKey: ["medication"]}).then(undefined));
    }, 100);
};

export const saveMedication = async (queryClient: QueryClient, id: string, name: string, unit: string, notes: string, frequencyType: string, frequencyDetail: number, archived: boolean) => {
    if (id === "" || name === "" || unit === "") {
        throw new Error("All fields are required");
    }

    let body: Medication = {
        name: name,
        unit: unit,
        frequencyType: frequencyType,
        frequencyDetail: frequencyDetail,
        notes: notes,
        archived: archived,
    };
    if (id !== "new") {
        body.medicationId = id;
    }

    let url = `${baseUrl}/medications/write`;
    return axios.post(url, body, getAxiosRequestOptions()).then((res): Promise<Medication> => {
        if (id !== "new") {
            let meds: Medication[] | undefined = queryClient.getQueryData(["medications", "list"]);
            if (meds) {
                // Update the medication in the cached medications list.
                let newMeds: Medication[] = [...meds];
                let idx = meds.findIndex((med: Medication) => med.medicationId === id);
                if (idx !== -1) {
                    newMeds[idx] = createMedication(res.data);
                }
            }
        }

        // Invalidate all cached medication queries.
        invalidateMedicationsCache(queryClient);
        return Promise.resolve(createMedication(res.data));
    }).catch(handleAxiosError("Failed to " + (id === "new" ? "create" : "save") + " medication"));
};

export const archiveMedication = async (id: string, isArchived: boolean): Promise<void> => {
    return axios.post(
        `${baseUrl}/medications/${isArchived ? "archive" : "unarchive"}`,
        {medicationId: id},
        getAxiosRequestOptions(),
    )
        .then((res) => Promise.resolve())
        .catch(handleAxiosError("Failed to " + (isArchived ? "archive" : "unarchive") + " medication"));
};
