import React from "react";
import randomString from "../../utils/RandomString";
import HelpButton from "../HelpPanel/HelpButton";

function TextareaField(props: {
    style?: React.CSSProperties,
    label: string | JSX.Element,
    labelStyle?: React.CSSProperties,
    value: string,
    setFunction: (value: string) => void,
    inputStyle?: React.CSSProperties,
    helpId?: string,
    withPopups?: boolean,
    isDirty?: boolean,
    setIsDirty?: (isDirty: boolean) => void,
}): JSX.Element {
    const randomId = randomString(8)

    return <div className="form-group basic input-wrapper"
                style={{
                    position: "relative",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    ...props.style,
                }}>
        {props.withPopups && props.helpId && <HelpButton id={props.helpId}/>}
        <label className="label" htmlFor={randomId}
               style={{textWrap: "balance", ...props.labelStyle}}>{props.label}</label>
        <textarea className="form-control" style={props.inputStyle} id={randomId} value={props.value}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                      if (props.setIsDirty) props.setIsDirty(true);
                      props.setFunction(e.target.value);
                  }}>{props.value}</textarea>
    </div>;
}

export default TextareaField;
