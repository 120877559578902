import React from "react";
import {IonIcon} from "@ionic/react";
import {NavLink} from "react-router-dom";

function BottomMenuItem(props: any) {
    const {url, active, icon, label} = props;

    // @ts-ignore
    return (
        <NavLink to={url} className={"item" + (active ? " active" : "")}>
            <div className="col">
                <IonIcon icon={icon}/>
                <strong>{label}</strong>
            </div>
        </NavLink>
    );
}

export default BottomMenuItem;
