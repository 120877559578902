import React, {useEffect, useState} from "react";
import TimeField from "../Fields/TimeField";
import {getCurrentTime} from "../../utils/DateTime";

function SelectTimeDialog(props: {
    title: string,
    currentTime: string,
    setFunction: (time: string) => void
}): JSX.Element {
    const [time, setTime] = useState<string>(props.currentTime);

    useEffect(() => {
        if (props.currentTime === "now") {
            setTime(getCurrentTime);
        } else {
            setTime(props.currentTime);
        }
    }, [props.currentTime]);

    const setIt = (newTime: string) => {
        props.setFunction(newTime);
    }

    return (
        <div className="modal fade dialogbox" id="select-time-dialog" data-bs-backdrop="static" tabIndex={-1}
             style={{display: "none"}} aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{props.title}</h5>
                    </div>
                    <form>
                        <div className="modal-body text-start mb-2">
                            <div className="form-group basic text-center">
                                <div className="input-wrapper"
                                     style={{
                                         display: "flex",
                                         gap: "2rem",
                                         alignItems: "center",
                                         justifyContent: "center"
                                     }}>
                                    <TimeField label={""} value={time} setFunction={setTime}/>
                                    <button type="button" className="btn btn-primary"
                                            onClick={() => setIt(time)} data-bs-dismiss="modal">
                                        Set time
                                    </button>
                                </div>
                                <div className="input-wrapper mt-2">
                                    <button type="button" className="btn btn-primary"
                                            onClick={() => setIt("now")} data-bs-dismiss="modal">
                                        Set to now
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="btn-inline">
                                <button type="button" className="btn btn-text-secondary" data-bs-dismiss="modal">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SelectTimeDialog;
