import axios from "axios";
import {baseUrl, getAxiosRequestOptions, handleAxiosError} from "./base";
import {QueryClient, useInfiniteQuery} from "@tanstack/react-query";
import {EprEntriesList} from "../types/Entry";

export const useFetchEntriesInfiniteQuery = (limit: number) => {
    return useInfiniteQuery({
        queryKey: ["epr", "entries-infinite"],
        queryFn: ({pageParam}) => fetchEntries(pageParam, limit),
        initialPageParam: "",
        getNextPageParam: (_lastPage, allPages) => allPages[allPages.length - 1].nextEntryWhen,
        staleTime: 24 * 60 * 60 * 1000, // Cache for 24 hours, cache expiry should ensure new entries are fetched.
    });
}

export const fetchEntriesQueryOptionsFunc = (fromDateTime: string, limit: number) => {
    return {
        queryKey: ["epr", "entries"],
        queryFn: () => fetchEntries(fromDateTime, limit),
        staleTime: 10 * (60 * 1000),
        gcTime: 15 * (60 * 1000),
    };
}

export const fetchEntries = async (fromDateTime: string, limit: number): Promise<EprEntriesList> => {
    return axios.post(
        `${baseUrl}/epr/list`,
        {"fromDateTime": fromDateTime, "maxResults": limit},
        getAxiosRequestOptions(),
    ).then((rsp) => {
        let res: EprEntriesList = rsp.data;
        if (!res.nextEntryWhen) res.nextEntryWhen = "";
        return res
    }).catch(handleAxiosError("Failed to load entries"));
};

export const invalidateEprCache = (queryClient: QueryClient): void => {
    setTimeout(() => {
        // Invalidate all cached EPR queries.
        queryClient.invalidateQueries({queryKey: ["epr"]});
    }, 100);
};
