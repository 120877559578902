import React, {useState} from "react";
import Medication, {MedicationDose} from "../../../../../types/Medication";
import {getCurrentTime} from "../../../../../utils/DateTime";
import TimeField from "../../../../../components/Fields/TimeField";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {invalidateMedicationsCache} from "../../../../../api/medications";
import {saveMedicationDose} from "../../../../../api/medication-doses";
import {dismissModal} from "../../../../../utils/DismissModal";
import FloatField from "../../../../../components/Fields/FloatField";
import {fetchMedicationOptionsQueryOptions, optionSingularToPlural} from "../../../../../api/medication-options";

function AddDoseActionSheet(props: {
    medication: Medication,
}): JSX.Element {
    const {medication} = props;
    const [time, setTime] = useState<string>(getCurrentTime());
    const [quantity, setQuantity] = useState<number>(0);
    const {data: lookups} = useQuery(fetchMedicationOptionsQueryOptions);
    const queryClient = useQueryClient();
    const saveMedicationDoseMutation = useMutation({
        mutationFn: () => saveMedicationDose(medication.medicationId!, time, quantity),
        onSuccess: (_: MedicationDose) => {
            invalidateMedicationsCache(queryClient);
            dismissModal("add-dose");
        },
        retry: 3,
    });

    return (
        <div className="modal fade action-sheet" id={"add-dose"} tabIndex={-1}
             aria-modal="true" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    {saveMedicationDoseMutation.isError && <div className="splash-page text-center mt-5 mb-5">
                        <h1 className="cart-title text-danger">Error</h1>
                        <p className="card-text text-danger">{saveMedicationDoseMutation.error.message}</p>
                        <button className="btn btn-primary" onClick={() => {
                            saveMedicationDoseMutation.mutate();
                        }}>Try again
                        </button>
                    </div>}
                    {saveMedicationDoseMutation.isPending && !saveMedicationDoseMutation.isError &&
                        <h1 className={"splash-page text-center mt-5 mb-5"}>
                            <div className="spinner-border text-primary" style={{width: "60px", height: "60px"}}
                                 role="status"></div>
                        </h1>}
                    {!saveMedicationDoseMutation.isPending && !saveMedicationDoseMutation.isError && <>
                        <div className="modal-header">
                            <h5 className="modal-title">Add dose</h5>
                        </div>
                        <div className="modal-body">
                            <div className="action-sheet-content">
                                <form>
                                    <div style={{display: "flex", gap: "1rem", justifyContent: "center"}}>
                                        <TimeField style={{flexGrow: 1}} label={"At what time?"} value={time}
                                                   setFunction={setTime}/>
                                        <FloatField label={
                                            <span>How many <span className={"text-primary"}
                                                                 style={{fontWeight: "bolder"}}>
            {lookups ? optionSingularToPlural(lookups.units, 2, medication.unit) : medication.unit}
        </span> should you take?</span>} value={quantity} setFunction={setQuantity}/>
                                    </div>
                                    <div className={"mt-2"}
                                         style={{display: "flex", gap: "1rem", justifyContent: "center"}}>
                                        <button className={"btn btn-secondary"} data-bs-dismiss={"modal"}
                                                onClick={(e) => e.preventDefault()}>
                                            Cancel
                                        </button>
                                        <button className={"btn btn-primary"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    saveMedicationDoseMutation.mutate();
                                                }}>
                                            Add dose...
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </div>
    );
}

export default AddDoseActionSheet;
