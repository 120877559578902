import React, {useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {archiveMedication, invalidateMedicationsCache} from "../../../../api/medications";
import Confirmation from "../../../../components/ActionWithConfirmation/Confirmation";
import ButtonWithConfirmation from "../../../../components/ActionWithConfirmation/ButtonWithConfirmation";
import {enqueueSnackbar} from "notistack";

function ArchiveForm(props: { id: string }) {
    const {id} = props;
    const queryClient = useQueryClient();
    const [confirming, setConfirming] = useState<boolean>(false);

    const archiveMedicationMutation = useMutation({
        mutationFn: (id: string) => archiveMedication(id, true),
        onSuccess: (_: void) => {
            // Not changing the browser location to make sure we jump up to the top of the page as this form is at the bottom
            window.scrollTo(0, 0);
            setConfirming(false);
            invalidateMedicationsCache(queryClient);
            enqueueSnackbar("Medication archived", {variant: "success"});
        },
        retry: 3,
    })

    return (
        <div className="section mt-2">
            <div className="card">
                <div className="card-body">
                    {archiveMedicationMutation.isError && <div className="text-center">
                        <h1 className="card-title text-danger">Error</h1>
                        <p className="card-text text-danger">{archiveMedicationMutation.error.message}</p>
                        <button className="btn btn-primary" onClick={() => {
                            archiveMedicationMutation.mutate(id);
                        }}>
                            Try again
                        </button>
                    </div>}
                    {!archiveMedicationMutation.isError && <>
                        {archiveMedicationMutation.isPending
                            ?
                            <h1 className={"splash-page mt-2"}>
                                <div className="spinner-border text-primary" style={{width: "36px", height: "36px"}}
                                     role="status"></div>
                            </h1>
                            :
                            <>
                                {confirming
                                    ?
                                    <Confirmation
                                        style={{marginTop: "1rem", marginBottom: "1rem"}}
                                        areYouSureLabel={"Are you sure you want to archive this medication?"}
                                        confirmLabel={"Yes, archive it..."}
                                        confirming={confirming} setConfirming={setConfirming}
                                        confirmedAction={() => archiveMedicationMutation.mutate(id)}/>
                                    :
                                    <div>
                                        <p>
                                            If you no longer take this medication you can archive it to hide it in the
                                            rest of the application. If you start taking it again you can easily restore
                                            it.
                                        </p>
                                        <ButtonWithConfirmation label={"Archive medication..."} confirming={confirming}
                                                                setConfirming={setConfirming}/>
                                    </div>
                                }
                            </>
                        }
                    </>}
                </div>
            </div>
        </div>
    );
}

export default ArchiveForm;
