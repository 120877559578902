import PullToRefresh from "react-simple-pull-to-refresh";
import React from "react";

const PullToRefreshWrapper = (props: {
    children: JSX.Element,
    onRefresh: () => Promise<any>,
    canFetchMore?: boolean,
    onFetchMore?: () => Promise<any>,
}): JSX.Element => {
    return (
        <PullToRefresh
            pullingContent={<div className={"text-center text-secondary"}><p>&#8615;&nbsp;&nbsp;pull to
                refresh&nbsp;&nbsp;&#8615;</p></div>}
            refreshingContent={<span className="mt-2 mb-1 spinner-border text-primary"/>}
            onRefresh={props.onRefresh}
            canFetchMore={props.canFetchMore}
            onFetchMore={props.onFetchMore}>
            <>
                {props.children}
            </>
        </PullToRefresh>
    );
}

export default PullToRefreshWrapper;
