import React from "react";
import {submitMedicationsTaken} from "../../../api/medication-take";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {TakeNotes} from "./TakeNotes";
import Loader from "../../../components/Loader/Loader";
import {
    fetchPendingTakeDateTakenOptions,
    fetchPendingTakeNotesOptionsFunc,
    fetchPendingTakeTimeTakenOptions,
    setPendingTakeTimeTaken
} from "../../../api/medication-pending-take";
import SelectTimeDialog from "../../../components/SelectTimeDialog/SelectTimeDialog";
import {enqueueSnackbar} from "notistack";

function SubmitButton(props: {
    date: string,
    time: string,
    active: boolean,
    medicationsToTake: string[],
    resetMedicationsToTake: () => void,
    notes: string,
}): JSX.Element {
    const queryClient = useQueryClient();
    const {data: currentNotes} = useQuery(fetchPendingTakeNotesOptionsFunc(props.date, props.time, props.notes));
    const {data: dateTaken} = useQuery(fetchPendingTakeDateTakenOptions);
    const {data: timeTaken} = useQuery(fetchPendingTakeTimeTakenOptions);
    const submitMedicationsTakenMutation = useMutation({
        mutationFn: () => submitMedicationsTaken(queryClient, props.date, props.time, dateTaken ?? "", timeTaken ?? "now", props.medicationsToTake),
        retry: 1,
        onSuccess: () => {
            enqueueSnackbar("Medications taken successfully", {variant: "success"});
            props.resetMedicationsToTake();
        },
    });

    if (!props.active) {
        return <></>;
    }

    return (
        <div className="section full border-top border-2 border-primary">
            {submitMedicationsTakenMutation.isPending
                ?
                <div className="wide-block pt-2 pb-2">
                    <Loader show={true}/>
                </div>
                :
                submitMedicationsTakenMutation.isError
                    ?
                    <div className="wide-block pt-2 pb-2">
                        <div className={"text-center text-danger mb-2"}>
                            {submitMedicationsTakenMutation.error.message}
                        </div>
                        <div className={"text-center"}>
                            <button className="btn btn-primary"
                                    onClick={() => submitMedicationsTakenMutation.mutate()}>
                                Retry
                            </button>
                        </div>
                    </div>
                    :
                    <>
                        <TakeNotes date={props.date} time={props.time} notes={props.notes} editable={true}/>
                        <div className="wide-block pt-2 pb-2">
                            {props.medicationsToTake.length > 0 && <div className="pb-2"
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            gap: "1rem",
                                                                        }}>
                                <span
                                    className={"text-dark"}>Taken {timeTaken === "now" ? "" : "at "}{timeTaken!}</span>
                                <button className={"btn btn-sm btn-secondary"} data-bs-toggle="modal"
                                        data-bs-target="#select-time-dialog">
                                    change
                                </button>
                            </div>}
                            <div className={"text-center"}>
                                <button className="btn btn-primary"
                                        disabled={(props.notes ?? "") === currentNotes && props.medicationsToTake.length === 0}
                                        onClick={() => submitMedicationsTakenMutation.mutate()}>
                                    {props.medicationsToTake.length > 0 || props.notes === currentNotes ?
                                        "Record as taken" : "Save notes"}
                                </button>
                            </div>
                        </div>
                    </>}
            <SelectTimeDialog title={"Set the time taken"} currentTime={timeTaken!}
                              setFunction={(newValue: string) => setPendingTakeTimeTaken(queryClient, newValue).then(undefined)}/>
        </div>
    );
}

export default SubmitButton;
