import React, {useState} from "react";
import Medication from "../../../../../types/Medication";
import {NavLink} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {MedicationVariant} from "../../../../../types/MedicationVariants";
import AddVariantActionSheet from "./AddVariantActionSheet";
import HelpButton from "../../../../../components/HelpPanel/HelpButton";
import {fetchMedicationVariantsQueryOptionsFunc} from "../../../../../api/medication-variants";
import EditVariantActionSheet from "./EditVariantActionSheet";

function Variants(props: { medication: Medication }): JSX.Element {
    const {medication} = props;
    const [editMedicationVariant, setEditMedicationVariant] = useState<MedicationVariant>();
    const {
        data: variants,
        isLoading: isLoadingVariants,
    } = useQuery(fetchMedicationVariantsQueryOptionsFunc(medication.medicationId!, 0));

    if (!medication || isLoadingVariants) return (
        <div className="section mt-2">
            <div className="section-title">Variants</div>
            <div className="card">
                <div className="spinner-border text-primary mt-2 mb-2"
                     style={{width: "30px", height: "30px", margin: "auto"}}
                     role="status"></div>
            </div>
        </div>
    );

    return (
        <>
            <div className="appCapsule mt-2" style={{position: "relative"}}>
                <div className="listview-title">
                    <HelpButton id={"medications-variants"} style={{top: "12px", right: "16px"}}/>
                    Variants
                </div>
                {!medication && <div className="card">
                    <div className="card-body">
                        <h1 className={"splash-page mt-2"}>
                            <div className="spinner-border text-primary" style={{width: "60px", height: "60px"}}
                                 role="status"></div>
                        </h1>
                    </div>
                </div>}
                {medication && <ul className="listview link-listview inset">
                    {variants && variants.length > 0 && variants.map((variant: MedicationVariant, _) =>
                        <li key={variant.strength + variant.form}>
                            <NavLink to={"#"} data-bs-toggle={"modal"} data-bs-target={"#edit-variant"}
                                     onClick={() => setEditMedicationVariant(variant)}>
                                <div>
                                    <strong>{variant.strength} {medication.unit}</strong> {variant.form !== medication.unit ? variant.form : ""}
                                    <footer className={"text-muted"}>
                                        {variant.stock ? variant.stock : "0"} available
                                    </footer>
                                </div>
                                <span className={"text-muted"}>Edit</span>
                            </NavLink>
                        </li>)}
                    <li>
                        <NavLink to={"#"} data-bs-toggle={"modal"} data-bs-target={"#add-variant"}>
                            <span>&nbsp;</span>
                            <span>Add a new variant</span>
                        </NavLink>
                    </li>
                </ul>}
            </div>
            {medication && <>
                <AddVariantActionSheet medication={medication}/>
                <EditVariantActionSheet medication={medication} variant={editMedicationVariant!}/>
            </>}
        </>
    );
}

export default Variants;
