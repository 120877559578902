import React from "react";
import {EprEntry, TakeScheduledMedicationsEntry, TakeUnscheduledMedicationsEntry} from "../../types/Entry";
import {formatFriendlyDate} from "../../utils/DateTime";
import EntryTakeScheduledMedications from "./EntryTakeScheduledMedications";
import EntryTakeUnscheduledMedications from "./EntryTakeUnscheduledMedications";

export function EntryWrapper(props: { children?: JSX.Element, date: string }) {
    if (props.date === "" && !props.children) {
        return <></>;
    }

    return <div className={"section mb-2"}>
        {props.date.length > 0 && <div className="section-title text-center">
            {formatFriendlyDate(new Date(Date.parse(props.date)))}
        </div>}
        {props.children ? props.children : <></>}
    </div>;
}

export default function EntryDisplay(props: { showDate: boolean, entry: EprEntry }) {
    const {showDate, entry} = props;

    switch (entry.type) {
        case "take-scheduled-medications":
            return <EntryTakeScheduledMedications showDate={showDate} when={entry.when}
                                                  data={JSON.parse(entry.content) as TakeScheduledMedicationsEntry}/>;

        case "take-unscheduled-medications":
            return <EntryTakeUnscheduledMedications showDate={showDate} when={entry.when}
                                                    data={JSON.parse(entry.content) as TakeUnscheduledMedicationsEntry}/>;

        default:
            console.log('Unknown entry type: ' + entry.type);
            return <EntryWrapper date={showDate ? entry.when : ""}>
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Entry type is not supported</h5>
                        <h6 className="card-subtitle">
                            An unexpected error has occurred. This has been reported.
                        </h6>
                    </div>
                </div>
            </EntryWrapper>;
    }
}
