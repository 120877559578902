import React from "react";
import {useQuery} from "@tanstack/react-query";
import {fetchMedicationOptionsQueryOptions, optionSingularToPlural} from "../../../api/medication-options";
import {MedicationStockForecast, MedicationStockForecastVariant} from "../../../types/MedicationVariants";

function ForecastTimelineItem(props: { daysLeft: number, meds: MedicationStockForecast[] }): JSX.Element {
    const {daysLeft, meds} = props;
    const {data: lookups} = useQuery(fetchMedicationOptionsQueryOptions);

    let key = '';
    if (daysLeft < 0) {
        if (daysLeft === -1) {
            key = "yesterday";
        } else {
            key = (-daysLeft).toString() + " days ago";
        }
    } else if (daysLeft === 0) {
        key = "today";
    } else if (daysLeft === 1) {
        key = "tomorrow";
    } else if (daysLeft === 7) {
        key = "1 week";
    } else if (daysLeft > 7) {
        let weeks = (daysLeft / 7).toFixed(1).toString();
        if (weeks[weeks.length - 1] === '0') {
            weeks = weeks.substring(0, weeks.length - 2);
        }
        key = +weeks + " weeks";
    } else {
        key = daysLeft.toString() + " days";
    }

    let dateTextStyle = {};
    let dateTextClass = "text-dark";
    let dotBgClass = "bg-secondary";
    if (daysLeft < 0) {
        dateTextStyle = {fontStyle: "italic"};
        dateTextClass = "text-muted";
        dotBgClass = "bg-secondary";
    } else if (daysLeft < 14) {
        dateTextStyle = {fontWeight: "bold"};
        dotBgClass = "bg-danger";
    } else if (daysLeft < 28) {
        dateTextStyle = {fontWeight: "bold"};
        dotBgClass = "bg-warning";
    } else {
        dotBgClass = "bg-secondary";
    }

    return (
        <div className="item mb-2">
            <span className={"time " + dateTextClass} style={dateTextStyle}>{key}</span>
            <div className={"dot " + dotBgClass}></div>
            <div className="content">
                {meds.map((med: MedicationStockForecast) => <div
                    key={med.medicationId} className={"mb-1"}>
                    <h4 className={"title " + dateTextClass + " mb-0"}
                        style={{...dateTextStyle}}>
                        {med.medicationName}
                    </h4>
                    {med.variants && med.variants.length > 0 &&
                        <div className={"text-muted ms-1"}>
                            {med.variants.map(
                                (variant: MedicationStockForecastVariant) => <div
                                    key={variant.variantForm + variant.variantStrength}>
                                    With {variant.stockLeft}x {variant.variantStrength} {med.medicationUnit} {lookups ? optionSingularToPlural(lookups.forms, parseFloat(variant.variantStrength), variant.variantForm) : variant.variantForm} left
                                </div>)}
                        </div>}
                </div>)}
            </div>
        </div>
    );
}

export default ForecastTimelineItem;
