import Medication from "./Medication";

export interface MedicationVariant {
    medicationId: string;
    medicationName: string;
    medicationUnit: string;
    medicationNotes: string;
    strength: number;
    canBeHalved: boolean;
    form: string;
    minimumWastage: number;
    stock: number;
    suggestedQuantity: number;
}

export interface MedicationStockForecastVariant {
    variantStrength: string;
    variantForm: string;
    stockLeft: number;
}

export interface MedicationStockForecast {
    medicationId: string;
    medicationName: string;
    medicationUnit: string;
    dateOutOfStock: string;
    daysLeft: number;
    variants: Array<MedicationStockForecastVariant>;
}

export interface MedicationVariantStockUpdate {
    medicationId: string;
    stockChange: number;
}

export interface MedicationVariantStockUpdates {
    modifications: Array<MedicationVariantStockUpdate>;
}

export interface StockUpdateFailure {
    medication: Medication,
    reason: string,
}

export interface StockUpdateFailures {
    failures: Array<StockUpdateFailure>;
}

export const createMedicationVariant = (objectIn: Partial<MedicationVariant>): MedicationVariant => {
    return {
        medicationId: (objectIn.hasOwnProperty("medicationId") ? objectIn.medicationId! : ""),
        medicationName: (objectIn.hasOwnProperty("medicationName") ? objectIn.medicationName! : ""),
        medicationUnit: (objectIn.hasOwnProperty("medicationUnit") ? objectIn.medicationUnit! : ""),
        medicationNotes: (objectIn.hasOwnProperty("medicationNotes") ? objectIn.medicationNotes! : ""),
        strength: (objectIn.hasOwnProperty("strength") ? objectIn.strength! : 0),
        form: (objectIn.hasOwnProperty("form") ? objectIn.form! : ""),
        canBeHalved: (objectIn.hasOwnProperty("canBeHalved") ? objectIn.canBeHalved! : false),
        minimumWastage: (objectIn.hasOwnProperty("minimumWastage") ? objectIn.minimumWastage! : 0),
        stock: (objectIn.hasOwnProperty("stock") ? objectIn.stock! : 0),
        suggestedQuantity: (objectIn.hasOwnProperty("suggestedQuantity") ? objectIn.suggestedQuantity! : 0),
    };
}
