import React, {useState} from "react";
import Medication from "../../../../../types/Medication";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {dismissModal} from "../../../../../utils/DismissModal";
import {fetchMedicationVariantsQueryOptionsFunc, saveMedicationVariant} from "../../../../../api/medication-variants";
import {MedicationVariant} from "../../../../../types/MedicationVariants";
import FloatField from "../../../../../components/Fields/FloatField";
import LookupField from "../../../../../components/Fields/LookupField";
import BoolSwitchField from "../../../../../components/Fields/BoolSwitchField";
import StockField from "../../../../../components/Fields/StockField";

function AddVariantActionSheet(props: {
    medication: Medication,
}): JSX.Element {
    const {medication} = props;
    const [strength, setStrength] = useState<number>(0);
    const [form, setForm] = useState<string>("tablet");
    const [canBeHalved, setCanBeHalved] = useState<boolean>(false);
    const [minimumWastage, setMinimumWastage] = useState<number>(0);
    const [stock, setStock] = useState<number>(0);
    const queryClient = useQueryClient();
    const saveMedicationVariantMutation = useMutation({
        mutationFn: () => saveMedicationVariant(
            medication.medicationId!,
            medication.name,
            medication.unit,
            strength, form,
            canBeHalved,
            minimumWastage,
            stock),
        onSuccess: (_: MedicationVariant) => {
            queryClient.invalidateQueries({
                queryKey: fetchMedicationVariantsQueryOptionsFunc(medication.medicationId!, 0).queryKey,
            }).then(() => undefined);
            dismissModal("add-variant");
        },
        retry: 3,
    });

    return (
        <div className="modal fade action-sheet" id={"add-variant"} tabIndex={-1}
             aria-modal="true" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    {saveMedicationVariantMutation.isError && <div className="splash-page text-center mt-5 mb-5">
                        <h1 className="cart-title text-danger">Error</h1>
                        <p className="card-text text-danger">{saveMedicationVariantMutation.error.message}</p>
                        <button className="btn btn-primary" onClick={() => {
                            saveMedicationVariantMutation.mutate();
                        }}>Try again
                        </button>
                    </div>}
                    {saveMedicationVariantMutation.isPending && !saveMedicationVariantMutation.isError &&
                        <h1 className={"splash-page text-center mt-5 mb-5"}>
                            <div className="spinner-border text-primary" style={{width: "60px", height: "60px"}}
                                 role="status"></div>
                        </h1>}
                    {!saveMedicationVariantMutation.isPending && !saveMedicationVariantMutation.isError && <>
                        <div className="modal-header">
                            <h5 className="modal-title">Add variant</h5>
                        </div>
                        <div className="modal-body">
                            <div className="action-sheet-content">
                                <form>
                                    <div style={{display: "flex", gap: "1rem", justifyContent: "center"}}>
                                        <FloatField label={"Strength (" + medication.unit + ")"}
                                                    value={strength} setFunction={setStrength}/>
                                        <LookupField type={"form"} label={"Form"} value={form}
                                                     setFunction={setForm}/>
                                    </div>
                                    <div style={{display: "flex", gap: "1rem", justifyContent: "center"}}>
                                        <BoolSwitchField label={"Can you take half of one?"}
                                                         value={canBeHalved} setFunction={setCanBeHalved}/>
                                        <FloatField label={"Minimum wastage per dose"} value={minimumWastage}
                                                    setFunction={setMinimumWastage}/>
                                    </div>
                                    <StockField label={"Stock"} value={stock}
                                                options={[1, 10, 16, 28, 50, 90, 0]}
                                                setFunction={setStock}/>
                                    <div style={{
                                        display: "flex",
                                        gap: "1rem",
                                        justifyContent: "center",
                                        marginTop: "1rem"
                                    }}>
                                        <button className={"btn btn-secondary"} data-bs-dismiss={"modal"}
                                                onClick={(e) => e.preventDefault()}>
                                            Cancel
                                        </button>
                                        <button className={"btn btn-primary"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    saveMedicationVariantMutation.mutate();
                                                }}>
                                            Add variant...
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </div>
    );
}

export default AddVariantActionSheet;

