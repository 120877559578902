import React from "react";
import {useQuery} from "@tanstack/react-query";
import randomString from "../../utils/RandomString";
import {fetchMedicationOptionsQueryOptions, optionsListSingular} from "../../api/medication-options";
import HelpButton from "../HelpPanel/HelpButton";

function LookupField(props: {
    type: "unit" | "form",
    style?: React.CSSProperties,
    label: string | JSX.Element,
    labelStyle?: React.CSSProperties,
    value: string,
    setFunction: (value: string) => void,
    inputStyle?: React.CSSProperties,
    helpId?: string,
    withPopups?: boolean,
    isDirty?: boolean,
    setIsDirty?: (isDirty: boolean) => void,
}): JSX.Element {
    const randomId = randomString(8)
    const {data: lookups} = useQuery(fetchMedicationOptionsQueryOptions);

    let optionsList: string[] = [];
    if (lookups) {
        switch (props.type) {
            case "unit":
                optionsList = optionsListSingular(lookups.units);
                break;
            case "form":
                optionsList = optionsListSingular(lookups.forms);
                break;
        }
    }

    return (
        <div className="form-group basic input-wrapper"
             style={{position: "relative", paddingLeft: "0px", paddingRight: "0px", ...props.style}}>
            {props.withPopups && props.helpId && <HelpButton id={props.helpId}/>}
            <label className="label" htmlFor={randomId}>{props.label}</label>
            <input type="text" className="form-control" id={randomId} value={props.value}
                   list={"list-" + randomId}
                   onFocus={(e) => e.target.select()}
                   onChange={(e) => {
                       if (props.setIsDirty) props.setIsDirty(true);
                       props.setFunction(e.target.value);
                   }}/>
            <datalist id={"list-" + randomId}>
                {optionsList.map((lookupItem, _) =>
                    <option key={lookupItem} value={lookupItem}/>)}
            </datalist>
        </div>
    );
}

export default LookupField;
