import React, {useEffect, useState} from "react";
import EditFrequencyActionSheet from "./EditFrequencyActionSheet";
import {MedicationDose} from "../../../../../types/Medication";
import {NavLink} from "react-router-dom";
import {summariseSchedule} from "./Utils";
import AddDoseActionSheet from "./AddDoseActionSheet";
import EditDoseActionSheet from "./EditDoseActionSheet";
import {fetchMedicationDoses} from "../../../../../api/medication-doses";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import HelpButton from "../../../../../components/HelpPanel/HelpButton";
import {fetchMedication} from "../../../../../api/medications";

function Schedule(props: { medicationId: string }): JSX.Element {
    const [frequencySummary, setFrequencySummary] = useState<string>("");
    const [editMedicationDose, setEditMedicationDose] = useState<MedicationDose>();
    const queryClient = useQueryClient();
    const {
        // isLoading,
        // isError: isLoadingError,
        data: medication,
        // error: loadingError
    } = useQuery({
        queryKey: ["medications", props.medicationId],
        queryFn: () => fetchMedication(queryClient, props.medicationId),
        staleTime: 10 * (60 * 1000),
        gcTime: 15 * (60 * 1000),
    })
    const {
        data: doses,
    } = useQuery({
        queryKey: ["medication", props.medicationId, "doses"],
        queryFn: () => fetchMedicationDoses(queryClient, props.medicationId),
        staleTime: 10 * (60 * 1000),
        gcTime: 15 * (60 * 1000),
    });

    useEffect(() => {
        if (medication) {
            setFrequencySummary(summariseSchedule(medication));
        }
    }, [medication]);

    if (!medication) return (
        <div className="section mt-2">
            <div className="section-title">Scheduled Doses</div>
            <div className="card">
                <div className="spinner-border text-primary mt-2 mb-2"
                     style={{width: "60px", height: "60px", margin: "auto"}}
                     role="status"></div>
            </div>
        </div>
    );

    return (
        <>
            <div className="appCapsule mt-2">
                <div className="listview-title" style={{position: "relative"}}>
                    <HelpButton id={"medications-schedule"} style={{top: "12px", right: "16px"}}/>
                    Scheduled Doses
                </div>
                {!medication && <div className="card">
                    <div className="card-body">
                        <h1 className={"splash-page mt-2"}>
                            <div className="spinner-border text-primary" style={{width: "60px", height: "60px"}}
                                 role="status"></div>
                        </h1>
                    </div>
                </div>}
                {medication && <ul className="listview link-listview inset">
                    <li>
                        <NavLink to={"#"} data-bs-toggle={"modal"} data-bs-target={"#edit-schedule"}>
                            <div>
                                <header>Frequency</header>
                                <span className={"text-primary"} style={{fontWeight: "bold", fontSize: "1.05rem"}}>
                                    {frequencySummary}
                                </span>
                            </div>
                            <span className={"text-muted"}>Edit</span>
                        </NavLink>
                    </li>
                    {medication.frequencyType !== "as-needed" && doses && doses.length > 0 && doses.map((dose: MedicationDose, _) => dose.dose &&
                        <li key={dose.time}>
                            <NavLink to={"#"} data-bs-toggle={"modal"}
                                     data-bs-target={"#edit-dose"} onClick={() => setEditMedicationDose(dose)}>
                                <div>
                                    At <span className={"text-primary"} style={{
                                    fontWeight: "bold", fontSize: "1.05rem"
                                }}>{dose.time}</span> take <span className={"text-primary"}
                                                                 style={{
                                                                     fontWeight: "bold",
                                                                     fontSize: "1.05rem"
                                                                 }}>{dose.dose} {medication.unit}</span>
                                </div>
                                <span className={"text-muted"}>Edit</span>
                            </NavLink>
                        </li>)}
                    {medication.frequencyType !== "as-needed" && <li>
                        <NavLink to={"#"} data-bs-toggle={"modal"} data-bs-target={"#add-dose"}>
                            <span>&nbsp;</span>
                            <span>Add a new dose</span>
                        </NavLink>
                    </li>}
                </ul>}
            </div>
            {medication && <>
                <EditFrequencyActionSheet medication={medication}/>
                <AddDoseActionSheet medication={medication}/>
                <EditDoseActionSheet medication={medication} medicationDose={editMedicationDose!}/>
            </>}
        </>
    );
}

export default Schedule;
