import React, {useEffect, useState} from "react";
import Medication, {MedicationDose} from "../../../../../types/Medication";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {invalidateMedicationsCache} from "../../../../../api/medications";
import {dismissModal} from "../../../../../utils/DismissModal";
import {deleteMedicationDose, saveMedicationDose} from "../../../../../api/medication-doses";
import {fetchMedicationOptionsQueryOptions, optionSingularToPlural} from "../../../../../api/medication-options";
import FloatField from "../../../../../components/Fields/FloatField";
import IconWithConfirmation from "../../../../../components/ActionWithConfirmation/IconWithConfirmation";
import {trashBinOutline} from "ionicons/icons";
import Confirmation from "../../../../../components/ActionWithConfirmation/Confirmation";

function EditDoseActionSheet(props: {
    medication: Medication,
    medicationDose: MedicationDose,
}): JSX.Element {
    const queryClient = useQueryClient();
    const {medication, medicationDose} = props;
    const [dose, setDose] = useState<number>(medicationDose ? medicationDose.dose : 1);
    const [confirming, setConfirming] = useState<boolean>(false);
    const saveMedicationDoseMutation = useMutation({
        mutationFn: () => saveMedicationDose(medication.medicationId!, medicationDose!.time, dose),
        onSuccess: (_: MedicationDose) => {
            invalidateMedicationsCache(queryClient);
            dismissModal("edit-dose");
        },
        retry: 3,
    });
    const deleteMedicationDoseMutation = useMutation({
        mutationFn: () => deleteMedicationDose(medication.medicationId!, medicationDose!.time),
        onSuccess: () => {
            invalidateMedicationsCache(queryClient);
            dismissModal("edit-dose");
        },
        retry: 3,
    });

    const SaveError = (): JSX.Element => {
        return (
            <div className="modal-body text-center mt-5 mb-5">
                <h1 className="cart-title text-danger">Error</h1>
                <p className="card-text text-danger">{saveMedicationDoseMutation.error!.message}</p>
                <button className="btn btn-primary" onClick={() => {
                    saveMedicationDoseMutation.mutate();
                }}>
                    Try again
                </button>
            </div>
        );
    };

    const Saving = (): JSX.Element => {
        return (
            <h1 className={"modal-body text-center mt-5 mb-5"}>
                <div className="spinner-border text-primary" style={{width: "60px", height: "60px"}}
                     role="status"></div>
            </h1>
        );
    }

    const ConfirmingDelete = (): JSX.Element => {
        return <Confirmation areYouSureLabel={"Are you sure you want to delete this dose?"}
                             confirmLabel={"Yes, delete it..."}
                             confirming={confirming && !deleteMedicationDoseMutation.isPending && !deleteMedicationDoseMutation.isError}
                             setConfirming={setConfirming}
                             confirmedAction={() => {
                                 setConfirming(false);
                                 deleteMedicationDoseMutation.mutate();
                             }}/>
    }

    const DeleteError = (): JSX.Element => {
        return (
            <div className="card">
                <div className="card-body">
                    <div className="text-center">
                        <h1 className="card-title text-danger">Error</h1>
                        <p className="card-text text-danger">{deleteMedicationDoseMutation.error!.message}</p>
                        <button className="btn btn-primary" onClick={() => {
                            deleteMedicationDoseMutation.mutate();
                        }}>Try again
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    const Deleting = (): JSX.Element => {
        return (
            <h1 className={"splash-page mt-2"}>
                <div className="spinner-border text-primary"
                     style={{width: "36px", height: "36px"}}
                     role="status"></div>
            </h1>
        );
    }

    const EditForm = (): JSX.Element => {
        const {data: lookups} = useQuery(fetchMedicationOptionsQueryOptions);
        const [editFormDose, setEditFormDose] = useState<number>(medicationDose ? medicationDose.dose : 1);

        useEffect(() => {
            if (medicationDose) {
                setEditFormDose(medicationDose.dose);
            }
        }, []);

        return (
            <div>
                <FloatField label={<span>How many <span className={"text-primary"} style={{fontWeight: "bolder"}}>
            {lookups ? optionSingularToPlural(lookups.units, 2, medication.unit) : medication.unit}
        </span> should you take at this time?</span>} labelStyle={{textAlign: "center"}} value={editFormDose}
                            setFunction={setEditFormDose} inputStyle={{textAlign: "center"}}/>
                <div className={"mt-2"} style={{display: "flex", gap: "1rem", justifyContent: "center"}}>
                    <button className={"btn btn-secondary"} data-bs-dismiss={"modal"}
                            onClick={(e) => e.preventDefault()}>Cancel
                    </button>
                    <button className={"btn btn-primary"}
                            onClick={(e) => {
                                e.preventDefault();
                                setDose(editFormDose);
                                saveMedicationDoseMutation.mutate();
                            }}>Update dose...
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="modal fade action-sheet" id={"edit-dose"} tabIndex={-1} aria-modal="true" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            <IconWithConfirmation
                                icon={trashBinOutline}
                                style={{
                                    position: "absolute",
                                    top: "0.75rem",
                                    right: "1rem",
                                    fontSize: "1.15rem",
                                }}
                                className={"text-danger"}
                                confirming={confirming} setConfirming={setConfirming}/>
                            {((confirming) ? "Delete" :
                                    (deleteMedicationDoseMutation.isPending ? "Deleting" :
                                        saveMedicationDoseMutation.isPending ? "Saving" :
                                            "Edit"))
                                + " dose at " +
                                (medicationDose ? medicationDose.time : "...") + (confirming ? "?" : "")}
                        </h5>
                    </div>
                    <div className="modal-content">
                        <div className="action-sheet-content">
                            {
                                (saveMedicationDoseMutation.isError &&
                                    <SaveError/>
                                ) ||
                                (saveMedicationDoseMutation.isPending &&
                                    <Saving/>)
                                ||
                                (confirming &&
                                    <ConfirmingDelete/>
                                ) ||
                                (deleteMedicationDoseMutation.isError &&
                                    <DeleteError/>
                                ) ||
                                (deleteMedicationDoseMutation.isPending &&
                                    <Deleting/>
                                ) ||
                                <EditForm/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditDoseActionSheet;
