import {IonIcon} from "@ionic/react";
import './Buttons.css';

function HorizontalBigButton(props: {
    className?: string,
    textClassName?: string,
    style?: React.CSSProperties,
    icon?: string,
    label?: string,
    onClick: () => void,
}): JSX.Element {
    return (
        <div className={props.className + " big-button bill-box pt-1 pb-1 ps-1 pe-1 pointer"}
             style={{display: "flex", justifyContent: "center", alignItems: "center", ...props.style}}
             onClick={props.onClick}>
            {props.icon && <IonIcon icon={props.icon} size={"small"} className={"text-primary me-1"}/>}
            {props.label &&
                <div className={props.textClassName ? props.textClassName : "text-primary"}
                     style={{fontSize: "0.7rem", lineHeight: "1rem"}}>{props.label}</div>}
        </div>
    );
}

export default HorizontalBigButton;
