import React, {useEffect} from 'react';
import {Navigate, Outlet, Route, Routes, useLocation} from "react-router-dom";
import BottomMenu from "./components/BottomMenu/BottomMenu";
import Entries from "./pages/Entries/Entries";
import AddEntry from "./pages/Entries/Add/AddEntry";
import ManageMedications from "./pages/Medications/Manage/ManageMedications";
import Appointments from "./pages/Appointments/Appointments";
import Account from "./pages/Account/Account";
import NotFound from "./pages/NotFound/NotFound";
import Measurements from "./pages/Measurements/Measurements";
import AllItems from "./pages/Medications/UpdateStock/AllItems";
import Summary from "./pages/Medications/Summary/Summary";
import Homepage from "./pages/Homepage/Homepage";
import ForecastStock from "./pages/Medications/ForecastStock/ForecastStock";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {Login} from "./pages/Homepage/Login";
import {Logout} from "./pages/Homepage/Logout";
import Welcome from "./pages/Welcome/Welcome";
import {SnackbarProvider} from "notistack";
import {useUser} from "./auth/useUser";
import LoginRequired from "./pages/Homepage/LoginRequired";
import {removePostAuthDest} from "./auth/store";
import EditMedication from "./pages/Medications/Manage/Edit/Edit";
import MedicationsHome from "./pages/Medications/MedicationsHome";

const ProtectedRoutes = () => {
    const {user, isLoading} = useUser();
    if (!isLoading && !user) {
        return <LoginRequired/>
    }
    removePostAuthDest();
    return <Outlet/>;
}

const WithBottomMenu = (props: { currentItem: string }) => {
    return (<>
        <Outlet/>
        <BottomMenu currentItem={props.currentItem}/>
    </>);
}

const queryClient = new QueryClient();

function App() {
    const location = useLocation();
    const [currentItem, setCurrentItem] = React.useState(location.pathname.substring(1).split("/")[0]);

    useEffect(() => {
        setCurrentItem(location.pathname.substring(1).split("/")[0]);
    }, [location]);

    return (
        <QueryClientProvider client={queryClient}>
            {/*<ScrollToTop/>*/}
            <SnackbarProvider preventDuplicate dense anchorOrigin={{vertical: "top", horizontal: "center"}}
                              autoHideDuration={2000} className={"mt-3"}>
                <div id={"appCapsule"}>
                    <Routes>
                        <Route path="/" element={<Homepage/>}/>
                        <Route path="/login" element={<Navigate to={"/login/google"} replace/>}/>
                        <Route path="/login/google" element={<Login provider="google"/>}/>

                        <Route element={<ProtectedRoutes/>}>
                            <Route element={<WithBottomMenu currentItem={currentItem}/>}>
                                <Route path="/logout" element={<Logout/>}/>

                                <Route path="/welcome" element={<Welcome/>}/>

                                <Route path="/account" element={<Account/>}/>

                                <Route path="/appointments" element={<Appointments/>}/>

                                <Route path="/entries" index element={<Entries/>}/>
                                <Route path="/entries/add" index element={<AddEntry/>}/>

                                <Route path="/measurements" index element={<Measurements/>}/>

                                <Route path="/medications/manage" element={<ManageMedications/>}/>
                                <Route path="/medications/manage/edit/:id" element={<EditMedication/>}/>
                                <Route path="/medications/summary" element={<Summary/>}/>
                                <Route path="/medications/stock/forecast" element={<ForecastStock/>}/>
                                <Route path="/medications/stock/update" element={<AllItems/>}/>
                                <Route path="/medications/:date" element={<MedicationsHome/>}/>
                                <Route path="/medications/:date/:time" element={<MedicationsHome/>}/>
                                <Route path="/medications" element={<MedicationsHome/>}/>

                                <Route path="*" element={<NotFound/>}/>
                            </Route>
                        </Route>
                    </Routes>
                </div>
            </SnackbarProvider>
        </QueryClientProvider>
    );
}

export default App;
