import React from "react";
import {daysArray, daysMap, isDayEnabled} from "../../pages/Medications/Manage/Edit/Schedule/Utils";

function DaysField(props: any): JSX.Element {
    const {value, setFunction} = props;

    return (
        <div className="form-group basic" style={{display: "flex", gap: "1rem"}}>
            <div className="input-wrapper" style={{flexGrow: 1}}>
                <div className="weekDays-selector text-center" style={{display: "flex", justifyContent: "center"}}>
                    {daysArray.map((d, i) => d.length > 0 && <div key={"weekday-" + d.toLowerCase()}>
                        <input type="checkbox" id={"weekday-" + d.toLowerCase()} className="weekday"
                               checked={isDayEnabled(value, daysMap.get(d))}
                               onChange={(e) => setFunction(daysMap.get(d), e.target.checked)}/>
                        <label htmlFor={"weekday-" + d.toLowerCase()}>{d.substring(0, 3)}</label>
                    </div>)}
                </div>
            </div>
        </div>
    );
}

export default DaysField;
