import {QueryClient} from "@tanstack/react-query";
import axios from "axios";
import {baseUrl, getAxiosRequestOptions, handleAxiosError} from "./base";

const getPreferenceQueryKey = (key: string, sub_key: string): string[] => ["preferences", key, sub_key];

const fetchPreference = async (t: string, key: string, sub_key: string, def: string | number | boolean): Promise<string | number | boolean> => {
    return axios.post(
        `${baseUrl}/preferences/${t}/get`,
        {"key": key, "subKey": sub_key},
        getAxiosRequestOptions(),
    )
        .then((res) => (!res.data.hasOwnProperty("value") ? def : res.data.value))
        .catch(handleAxiosError("Failed to read preference"));
};

export const fetchStringPreferenceQueryOptions = (key: string, sub_key: string): {
    queryKey: string[],
    queryFn: () => Promise<string>,
    staleTime: number,
    gcTime: number,
} => ({
    queryKey: getPreferenceQueryKey(key, sub_key),
    queryFn: () => fetchPreference("string", key, sub_key, "").then((res) => res.toString()),
    staleTime: 10 * (60 * 1000),
    gcTime: 15 * (60 * 1000),
});

export const fetchIntPreferenceQueryOptions = (key: string, sub_key: string): {
    queryKey: string[],
    queryFn: () => Promise<number>,
    staleTime: number,
    gcTime: number,
} => ({
    queryKey: getPreferenceQueryKey(key, sub_key),
    queryFn: () => fetchPreference("int", key, sub_key, 0).then((res) => parseInt(res.toString())),
    staleTime: 10 * (60 * 1000),
    gcTime: 15 * (60 * 1000),
});

export const fetchBoolPreferenceQueryOptions = (key: string, sub_key: string): {
    queryKey: string[],
    queryFn: () => Promise<boolean>,
    staleTime: number,
    gcTime: number,
} => ({
    queryKey: getPreferenceQueryKey(key, sub_key),
    // eslint-disable-next-line eqeqeq
    queryFn: () => fetchPreference("bool", key, sub_key, false).then((res) => res == true),
    staleTime: 10 * (60 * 1000),
    gcTime: 15 * (60 * 1000),
});

const setPreference = async (queryClient: QueryClient, t: string, key: string, sub_key: string, value: string | number | boolean): Promise<void> => {
    return axios.post(
        `${baseUrl}/preferences/${t}/set`,
        {"key": key, "subKey": sub_key, "value": value},
        getAxiosRequestOptions(),
    ).then((res) => {
        queryClient.invalidateQueries({queryKey: getPreferenceQueryKey(key, sub_key)});
    }).catch(handleAxiosError("Failed to set preference"));
};

export const setStringPreference = (queryClient: QueryClient, key: string, sub_key: string) => ({
    mutationFn: (value: string) => setPreference(queryClient, "string", key, sub_key, value),
    retry: 3,
});

export const setIntPreference = (queryClient: QueryClient, key: string, sub_key: string, value: string) => ({
    mutationFn: (value: number) => setPreference(queryClient, "int", key, sub_key, value),
    retry: 3,
});

export const setBoolPreference = (queryClient: QueryClient, key: string, sub_key: string) => ({
    mutationFn: (value: boolean) => setPreference(queryClient, "bool", key, sub_key, value),
    retry: 3,
});
