import React from "react";
import {IonIcon} from "@ionic/react";
import {NavLink} from "react-router-dom";

function BottomMenuActionItem(props: any) {
    const {url, icon, large} = props;

    // @ts-ignore
    return (
        <NavLink to={url}>
            <div className="item col">
                <div className={"action-button" + (large ? " large" : "")}>
                    <IonIcon className={"md hydrated"} icon={icon}/>
                </div>
            </div>
        </NavLink>
    );
}

export default BottomMenuActionItem;
