import React from "react";

export function ButtonWithConfirmation(props: {
    label: string,
    buttonClassName?: string,
    confirming: boolean,
    setConfirming: (confirming: boolean) => void,
}) {
    const buttonClassName = props.buttonClassName ? props.buttonClassName : "btn-danger";

    if (props.confirming) {
        return <></>;
    }

    return <div className={"text-center"}>
        <button type="button" className={"btn " + buttonClassName}
                onClick={() => props.setConfirming(true)}>
            {props.label}
        </button>
    </div>;
}

export default ButtonWithConfirmation;
