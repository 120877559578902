import {MedicationVariant} from "./MedicationVariants";

export interface MedicationOptions {
    units: Map<string, string>;
    forms: Map<string, string>;
}

export interface MedicationDose {
    medicationId: string;
    time: string;
    dose: number;
}

export default interface Medication {
    medicationId?: string;
    name: string;
    unit: string;
    frequencyType?: string;
    frequencyDetail?: number;
    notes?: string;
    scheduleSummary?: string;
    archived: boolean;
    variantsIncluded?: boolean;
    variants?: Array<MedicationVariant>;
};

export const MedicationFrequencyTypeRegular = "regular";
export const MedicationFrequencyTypeSpecific = "specific";
export const MedicationFrequencyTypeAsNeeded = "as-needed";

export interface SummaryMedication {
    name: string;
    dose: number;
    unit: string;
}

export interface SummaryDoses {
    time: string;
    medications: Array<SummaryMedication>;
}

export interface SummaryWeekly {
    title: string;
    doses: Array<SummaryDoses>;
}

export interface SummaryInterval {
    title: string;
    doses: Array<SummaryDoses>;
}

export interface MedicationSummary {
    weekly: Array<SummaryWeekly>;
    interval: Array<SummaryInterval>;
    asNeeded: Array<string>;
}

export const createMedication = (objectIn: Partial<Medication>): Medication => {
    return {
        medicationId: (objectIn.hasOwnProperty("medicationId") ? objectIn.medicationId! : ""),
        name: (objectIn.hasOwnProperty("name") ? objectIn.name! : ""),
        unit: (objectIn.hasOwnProperty("unit") ? objectIn.unit! : ""),
        frequencyType: (objectIn.hasOwnProperty("frequencyType") ? objectIn.frequencyType! : ""),
        frequencyDetail: (objectIn.hasOwnProperty("frequencyDetail") ? objectIn.frequencyDetail! : 1),
        notes: (objectIn.hasOwnProperty("notes") ? objectIn.notes! : ""),
        archived: (objectIn.hasOwnProperty("archived") ? objectIn.archived! : false),
    };
}
