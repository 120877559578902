function LoadingError(props: {
    className?: string,
    hasError: boolean,
    errorMessage: string,
    onRetry: () => void
}): JSX.Element {
    return (<>
        {props.hasError && <div className={"text-center pb-2 " + props.className}>
            <h1 className="cart-title text-danger">Error</h1>
            <p className="card-text text-danger">{props.errorMessage}</p>
            <button className="btn btn-primary" onClick={() => props.onRetry()}>Try again...</button>
        </div>}
    </>);
}

export default LoadingError;
