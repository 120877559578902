import React from "react";
import {ScheduleItem} from "../../../types/MedicationSchedules";

const SkippedMedicationItem = (props: {
    entry: ScheduleItem,
}) => {
    return <li style={{display: "block"}}>
        <div className="item">
            <div className="in" style={{display: "flex", alignItems: "center"}}>
                <div style={{flexGrow: 1, margin: "auto 0 auto 0"}}>
                    {props.entry.medicationName}
                    {props.entry.medicationNotes !== "" ? <footer>{props.entry.medicationNotes}</footer> : <></>}
                </div>
                <div className="text-center" style={{width: "4rem"}}>{props.entry.timeTaken}</div>
            </div>
        </div>
    </li>;
}

export default SkippedMedicationItem;
