import React, {useState} from "react";
import Medication from "../../../types/Medication";
import ModifyStockDialog from "../Manage/Edit/ModifyStockDialog";

function Item(props: {
    medication: Medication,
    setFunction: (id: string, change: number) => void
    applyFailed: boolean,
    reason: string,
}): JSX.Element {
    const {medication, setFunction, applyFailed, reason} = props;
    const [change, setChange] = useState<number>(0);

    const adjustChange = (adjustment: number) => {
        setChange(change + adjustment);
        setFunction(medication.medicationId!, change + adjustment);
    };

    if (applyFailed && !reason) {
        return <></>;
    }

    return (
        <li>
            <div className="in" style={{flexGrow: 1}}>
                <div>
                    <h3 style={{fontWeight: "normal"}}>{medication.name} {medication.unit}</h3>
                    {applyFailed && <p className={"text-danger"}>{reason}</p>}
                    <footer className={"text-black"} style={{display: "flex"}}>
                        <div style={{flexGrow: 1}}>
                            {/*Current: {medication.stock ? medication.stock : "0"}<br/>*/}
                            Change: {change === 0 ? "0" : (change > 0 ? "+" : "") + change.toString()}
                        </div>
                        <div style={{
                            textAlign: "right",
                            marginLeft: "1rem",
                            display: "flex",
                            flexDirection: "row",
                            gap: "0.5rem"
                        }}>
                            <button type="button" className="btn btn-outline-secondary btn-sm"
                                    onClick={() => adjustChange(16)}>+16
                            </button>
                            <button type="button" className="btn btn-outline-secondary btn-sm"
                                    onClick={() => adjustChange(28)}>+28
                            </button>
                            <button type="button" className="btn btn-outline-secondary btn-sm"
                                    onClick={() => adjustChange(90)}>+90
                            </button>
                            <button type="button" className="btn btn-outline-secondary btn-sm"
                                    data-bs-toggle="modal" data-bs-target={"#add-" + medication.medicationId}>+?
                            </button>
                            <button type="button" className="btn btn-outline-danger btn-sm"
                                    data-bs-toggle="modal" data-bs-target={"#subtract-" + medication.medicationId}>-?
                            </button>
                            <button type="button" className="btn btn-outline-danger btn-sm"
                                    onClick={() => adjustChange(0 - change)}>=0
                            </button>
                        </div>
                    </footer>
                </div>
            </div>
            <ModifyStockDialog id={"add-" + medication.medicationId}
                               context={medication.name + " " + medication.unit} value={0}
                               setFunction={(adjustment: string) => adjustChange(parseInt(adjustment))}
                               addOrSubtract={"add"}/>
            <ModifyStockDialog id={"subtract-" + medication.medicationId}
                               context={medication.name + " " + medication.unit} value={0}
                               setFunction={(adjustment: string) => adjustChange(parseInt(adjustment))}
                               addOrSubtract={"subtract"}/>
        </li>
    );
}

export default Item;
