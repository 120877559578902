import React from "react";
import randomString from "../../utils/RandomString";
import HelpButton from "../HelpPanel/HelpButton";

function BoolSwitchField(props: {
    style?: React.CSSProperties,
    label: string | JSX.Element,
    labelStyle?: React.CSSProperties,
    value: boolean,
    setFunction: (value: boolean) => void,
    inputStyle?: React.CSSProperties,
    helpId?: string,
    withPopups?: boolean,
    isDirty?: boolean,
    setIsDirty?: (isDirty: boolean) => void,
}): JSX.Element {
    const randomId = randomString(8)

    const updateValue = (newValue: boolean) => {
        props.setFunction(newValue);
        if (props.setIsDirty) {
            props.setIsDirty(true);
        }
    }

    return (
        <div className="form-group basic"
             style={{
                 position: "relative",
                 paddingLeft: "0px",
                 paddingRight: "0px",
                 ...props.style,
             }}>
            {props.withPopups && props.helpId && <HelpButton id={props.helpId}/>}
            <label className="label" htmlFor={randomId}
                   style={{textWrap: "balance", ...props.labelStyle}}>{props.label}</label>
            <div className={"pt-1 pe-2"}
                 style={{display: "flex", justifyContent: "center", alignItems: "center", ...props.inputStyle}}>
                <div className={"pe-2 pointer " + (!props.value && "text-primary")}
                     onClick={() => updateValue(false)}>No
                </div>
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id={randomId}
                           checked={props.value} onChange={(e) => updateValue(e.target.checked)}/>
                    <label className="form-check-label" htmlFor={randomId}></label>
                </div>
                <div className={"ps-2 pointer " + (props.value && "text-primary")}
                     onClick={() => updateValue(true)}>Yes
                </div>
            </div>
        </div>
    );
}

export default BoolSwitchField;
