import {useQuery, useQueryClient} from "@tanstack/react-query";
import {fetchScheduleQueryFunc} from "../../../api/medication-schedules";
import LoadingWrapper from "../../../components/Loader/LoadingWrapper";
import TakeMedicationItem from "./TakeMedicationItem";
import React, {useEffect} from "react";
import TakenMedicationItem from "./TakenMedicationItem";
import {
    ScheduledDoseStatusPending,
    ScheduledDoseStatusSkipped,
    ScheduledDoseStatusTaken,
    ScheduleItem
} from "../../../types/MedicationSchedules";
import SubmitButton from "./SubmitButton";
import SkippedMedicationItem from "./SkippedMedicationItem";
import {IonIcon} from "@ionic/react";
import {add, remove} from "ionicons/icons";
import {setPendingTakeNotes} from "../../../api/medication-pending-take";

function TakeList(props: {
    date: string,
    time: string,
    selected: boolean,
    allowSkipped: boolean,
}): JSX.Element {
    const {date, time} = props;
    const queryClient = useQueryClient();
    const [pendingDoses, setPendingDoses] = React.useState<ScheduleItem[]>([]);
    const [takenDoses, setTakenDoses] = React.useState<ScheduleItem[]>([]);
    const [skippedDoses, setSkippedDoses] = React.useState<ScheduleItem[]>([]);
    const [asNeededCount, setAsNeededCount] = React.useState<number>(0);
    const [otherCount, setOtherCount] = React.useState<number>(0);
    const [otherOpen, setOtherOpen] = React.useState<boolean>(false);
    const [medicationsToTake, setMedicationsToTake] = React.useState<string[]>([]);
    const [resetDosesTrigger, setResetDosesTrigger] = React.useState<number>(0);
    const {
        isLoading: loading,
        isError: isLoadingError,
        data: schedule,
        error: loadingError,
        refetch,
    } = useQuery(fetchScheduleQueryFunc(props.date, props.time, props.selected));

    useEffect(() => {
        if (schedule) {
            setPendingDoses(schedule.items.filter(item => item.status === ScheduledDoseStatusPending));
            setTakenDoses(schedule.items.filter(item => item.status === ScheduledDoseStatusTaken));
            setSkippedDoses(schedule.items.filter(item => item.status === ScheduledDoseStatusSkipped));
            if (time === "unscheduled") {
                setAsNeededCount(schedule.items.filter(item => item.time === "as-needed").length);
                setOtherCount(schedule.items.filter(item => item.time !== "as-needed").length);
            }

            setPendingTakeNotes(queryClient, date, time, schedule.notes === undefined ? "" : schedule.notes);
        }
    }, [queryClient, date, time, schedule]);

    if (!props.selected) {
        return <></>;
    }

    const resetMedicationsToTake = () => {
        setTimeout(() => {
            setResetDosesTrigger(prev => prev + 1);
        }, 100);
    }

    const setMedicationStatus = (medicationId: string, status: string) => {
        if (!medicationId) return;
        switch (status) {
            case ScheduledDoseStatusTaken:
            case ScheduledDoseStatusSkipped:
                setMedicationsToTake(curr => {
                    let newMedications = [...curr];
                    if (!newMedications.includes(medicationId.toString())) {
                        newMedications.push(medicationId.toString());
                    }
                    return newMedications;
                })
                break;

            default:
                setMedicationsToTake(curr => {
                    let newMedications = [...curr];
                    let idx = newMedications.indexOf(medicationId.toString());
                    if (idx !== -1) {
                        newMedications.splice(idx, 1);
                    }
                    return newMedications;
                })
                break;
        }
    };

    return (
        <LoadingWrapper className="mt-2" isLoading={loading} hasError={isLoadingError}
                        errorMessage={loadingError ? loadingError.message : ""} onRetry={refetch}>
            <div className="section full">
                {pendingDoses.length === 0 ?
                    <div className={"text-center mt-2 mb-2"} style={{textWrap: "balance"}}>
                        All scheduled medications taken
                    </div>
                    :
                    <div className={"mb-2"}>
                        {props.time === "unscheduled" ?
                            <>
                                {asNeededCount > 0 &&
                                    <ul className="listview simple-listview">
                                        {pendingDoses.filter((dose) => dose.time === "as-needed").map(dose =>
                                            <TakeMedicationItem key={dose.medicationId}
                                                                date={props.date} time={props.time} entry={dose}
                                                                allowSkipped={props.allowSkipped}
                                                                resetDosesTrigger={resetDosesTrigger}
                                                                setStatus={(newStatus) => setMedicationStatus(dose.medicationId, newStatus)}/>
                                        )}
                                    </ul>
                                }
                                {otherCount > 0 && <>
                                    {asNeededCount > 0 &&
                                        <div className="mt-2" onClick={() => setOtherOpen(!otherOpen)}
                                             style={{
                                                 display: "flex",
                                                 justifyContent: "center",
                                                 alignItems: "center",
                                                 cursor: "pointer",
                                             }}>
                                            <IonIcon icon={otherOpen ? remove : add} className={"text-secondary"}
                                                     style={{marginLeft: "8px", fontSize: "1.35em"}}/>
                                            <div className="listview-title" style={{flexGrow: 1, paddingLeft: "0"}}>
                                                Other medications
                                            </div>
                                            <div className="badge badge-secondary"
                                                 style={{marginRight: "16px"}}>{otherCount}</div>
                                        </div>
                                    }
                                    {(asNeededCount === 0 || otherOpen) &&
                                        <ul className="listview simple-listview">
                                            {pendingDoses.filter((dose) => dose.time !== "as-needed").map(dose =>
                                                <TakeMedicationItem key={dose.medicationId}
                                                                    date={props.date} time={props.time} entry={dose}
                                                                    allowSkipped={props.allowSkipped}
                                                                    resetDosesTrigger={resetDosesTrigger}
                                                                    setStatus={(newStatus) => setMedicationStatus(dose.medicationId, newStatus)}/>
                                            )}
                                        </ul>
                                    }
                                </>}
                            </>
                            :
                            <ul className="listview simple-listview">
                                {pendingDoses.map(dose =>
                                    <TakeMedicationItem key={dose.medicationId} date={props.date} time={props.time}
                                                        entry={dose} allowSkipped={props.allowSkipped}
                                                        resetDosesTrigger={resetDosesTrigger}
                                                        setStatus={(newStatus) => setMedicationStatus(dose.medicationId, newStatus)}/>
                                )}
                            </ul>
                        }
                        <SubmitButton date={props.date} time={props.time} active={props.selected}
                                      medicationsToTake={medicationsToTake ? medicationsToTake : []}
                                      resetMedicationsToTake={resetMedicationsToTake}
                                      notes={schedule ? schedule.notes : ""}/>
                    </div>}
                {takenDoses.length > 0 && <div className={"mb-2"}>
                    <div className="section-title bg-primary text-light"
                         style={{display: "flex", alignItems: "center"}}>
                        <div style={{flexGrow: 1}}>Medications taken</div>
                        <div className={"text-center"} style={{width: "4rem", fontSize: "0.8rem"}}>Time</div>
                    </div>
                    <ul className="listview simple-listview">
                        {takenDoses.map(dose => <TakenMedicationItem key={dose.medicationId} entry={dose}/>)}
                    </ul>
                </div>}
                {skippedDoses.length > 0 && <div className={"mb-2"}>
                    <div className="section-title bg-warning text-dark" style={{display: "flex", alignItems: "center"}}>
                        <div style={{flexGrow: 1}}>Medications skipped</div>
                        <div className={"text-center"} style={{width: "4rem", fontSize: "0.8rem"}}>Time</div>
                    </div>
                    <ul className="listview simple-listview">
                        {skippedDoses.map(dose => <SkippedMedicationItem key={dose.medicationId} entry={dose}/>)}
                    </ul>
                </div>}
                {pendingDoses.length === 0 && <div className={"section full mb-2"}>
                    <div className="section-title bg-secondary text-light"
                         style={{display: "flex", alignItems: "center"}}>
                        <div style={{flexGrow: 1}}>Notes</div>
                    </div>
                    <div className={"bg-light p-2" + (schedule?.notes === "" ? " text-muted" : " text-dark")}
                         style={{whiteSpace: "pre-line", lineHeight: "1.1rem"}}>
                        {schedule &&
                            (schedule.notes
                                    ?
                                    <p className="card-text">{schedule.notes ?? ""}</p>
                                    :
                                    <p className="card-text" style={{fontStyle: "italic"}}>No notes.</p>
                            )
                        }
                    </div>
                </div>}
            </div>
        </LoadingWrapper>
    );
}

export default TakeList;
