import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {fetchBoolPreferenceQueryOptions, setBoolPreference} from "../../api/preferences";
import {useEffect} from "react";

function HelpCard(props: {
    children: JSX.Element[]
    title: string | JSX.Element,
    hidden: boolean,
    setHiddenFn: (isHidden: boolean) => void,
    hidePreferenceKey: string,
    hidePreferenceSubKey: string,
}): JSX.Element {
    const {children, title, hidden, setHiddenFn, hidePreferenceKey, hidePreferenceSubKey} = props;
    const queryClient = useQueryClient();
    const {data: hideHelpPreference} = useQuery(
        fetchBoolPreferenceQueryOptions(hidePreferenceKey, hidePreferenceSubKey));
    const setHideHelpPreferenceMutation = useMutation(
        setBoolPreference(queryClient, hidePreferenceKey, hidePreferenceSubKey));

    useEffect(() => {
        setHiddenFn(hideHelpPreference === undefined ? true : hideHelpPreference);
    }, [setHiddenFn, hideHelpPreference]);


    if (hidden) return <></>;

    return <div className="section mb-2">
        <div id="help-card" className="card">
            <div className="card-header">{title}</div>
            <div className="card-body">
                {children}
                <div className={"form-group"}
                     style={hideHelpPreference ? {textAlign: "center"} : {display: "flex", justifyContent: "center"}}>
                    <button className={"btn btn-" + (hideHelpPreference ? "primary" : "secondary")}
                            onClick={() => setHiddenFn(true)}>Close
                    </button>
                    {!hideHelpPreference && <div style={{textAlign: "right", flexGrow: 1}}>
                        <button className={"btn btn-primary"}
                                onClick={() => setHideHelpPreferenceMutation.mutate(true)}>
                            Close and don't show again
                        </button>
                    </div>}
                </div>
            </div>
        </div>
    </div>;
}

export default HelpCard;
