import {User} from "./useUser";

const AUTH_USER_NAME = "user";
const AUTH_TOKEN_NAME = 'authToken';
const POST_AUTH_DEST_NAME = "postAuthDest";

export function setUser(user: User) {
    localStorage.setItem(AUTH_USER_NAME, JSON.stringify(user));
}

export function getUser(): User | null {
    const user = localStorage.getItem(AUTH_USER_NAME);
    if (user) {
        return JSON.parse(user);
    }
    return null;
}

export function removeUser(): void {
    localStorage.removeItem(AUTH_USER_NAME);
}

export function setUserToken(token: string) {
    localStorage.setItem(AUTH_TOKEN_NAME, token);
}

export function getUserToken(): string | null {
    return localStorage.getItem(AUTH_TOKEN_NAME);
}

export function removeUserToken(): void {
    localStorage.removeItem(AUTH_TOKEN_NAME);
}

export function setPostAuthDest(dest: string) {
    localStorage.setItem(POST_AUTH_DEST_NAME, dest);
}

export function getPostAuthDest(): string | null {
    return localStorage.getItem(POST_AUTH_DEST_NAME);
}

export function removePostAuthDest(): void {
    localStorage.removeItem(POST_AUTH_DEST_NAME);
}
