import React, {useState} from "react";
import randomString from "../../../../utils/RandomString";

function ModifyStockDialog(props: any): JSX.Element {
    const {id, context, value, setFunction, addOrSubtract} = props;
    const [adjustment, setAdjustment] = useState("0");
    const randomId = randomString(8);

    const applyAdjustment = () => {
        if (addOrSubtract === "subtract") {
            setFunction(parseInt(value) - parseInt(adjustment === "" ? "0" : adjustment));
        } else {
            setFunction(parseInt(value) + parseInt(adjustment === "" ? "0" : adjustment));
        }
    };

    return (
        <div className="modal fade dialogbox" id={id}
             data-bs-backdrop="static" tabIndex={-1}
             aria-modal="true" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div style={{flexGrow: 1}}>
                            <h5 className="modal-title text-center">{addOrSubtract === "subtract" ? "Decrease" : "Add"} stock</h5>
                            {context && context.length &&
                                <h6 className="modal-subtitle text-center" style={{display: "block"}}>{context}</h6>}
                        </div>
                    </div>
                    <form>
                        <div className="modal-body text-start mb-2">
                            <div className="form-group">
                                <div className="input-wrapper">
                                    <label className="label"
                                           htmlFor={randomId}>{addOrSubtract === "subtract" ? "Decrease" : "Increase"} stock
                                        by...</label>
                                    <input type="number" pattern="[0-9.]*" inputMode="numeric"
                                           id={randomId} className="form-control"
                                           value={adjustment}
                                           onChange={(e) => setAdjustment(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="btn-inline">
                                <div className="btn btn-text-secondary pointer" data-bs-dismiss="modal">Cancel</div>
                                <div className="btn btn-text-primary" data-bs-dismiss="modal"
                                     onClick={() => applyAdjustment()}>Apply
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ModifyStockDialog;
