import {baseUrl} from "../api/base";
import axios from "axios";
import {useSnackbar} from "notistack";
import {getPostAuthDest, setUserToken} from "./store";

export const useLogin = (): (provider: string) => Promise<string> => {
    const {enqueueSnackbar} = useSnackbar();

    return async (provider: string): Promise<string> => {
        return axios.post(
            `${baseUrl}/login/${provider}`,
            {payload: window.location.search},
        ).then((res) => {
            // The server will put the token in a cookie, so we don't need to do anything here
            const action: { op: string, data: string } = res.data;
            switch (action.op) {
                case "redirect": {
                    return action.data;
                }
                case "created": {
                    setUserToken(action.data);
                    // This is a new user, take them to the onboarding flow
                    return "/welcome";
                }
                case "complete": {
                    setUserToken(action.data);
                    // TODO: This should be a redirect to the page they were on before logging in
                    let dest = getPostAuthDest();
                    if (!dest) {
                        dest = "/entries";
                    }
                    return dest;
                }
                case "unauthorised": {
                    enqueueSnackbar("Sorry, authorised users only at this time",
                        {variant: "error"});
                    return "/";
                }
                case "banned": {
                    enqueueSnackbar("Authorised users only!",
                        {variant: "error"});
                    return "/";
                }
                default: {
                    console.log("Unknown response from server: " + action.op);
                    enqueueSnackbar("Failed to login", {variant: "error"});
                    return "/";
                }
            }
        }).catch((error) => {
            // TODO: Log to Axiom/Sentry?
            console.log('Login error', error.message);
            enqueueSnackbar("Failed to login", {variant: "error"});
            return "/";
        });
    }
}
