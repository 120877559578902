import React from "react";
import Loader from "../../components/Loader/Loader";

export const FullScreenLoader = (props: { title: string }) => {
    return (
        <div className={"section homepage"} style={{
            marginTop: "15svh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "2.5rem",
        }}>
            <div>
                <h3>{props.title}</h3>
            </div>
            <Loader show={true}/>
        </div>
    );
};
