import React from "react";
import {NavLink} from "react-router-dom";
import Medication from "../../../types/Medication";

function Item(props: { medication: Medication, disabled?: boolean }): JSX.Element {

    return (
        <li>
            <NavLink to={"/medications/manage/edit/" + props.medication.medicationId} className="item">
                <div className="in">
                    <div className={props.disabled ? "text-muted" : ""}>
                        {props.medication.name}
                        {props.medication.notes &&
                            <p className={(props.disabled ? "text-muted" : "text-secondary") + " mt-0 mb-1"} style={{
                                whiteSpace: "pre-line",
                                lineHeight: "1.1rem",
                                fontStyle: "italic",
                            }}>{props.medication.notes}</p>}
                        <footer className={props.disabled ? "text-muted" : ""}>
                            {!props.medication.archived && props.medication.scheduleSummary}
                        </footer>
                    </div>
                </div>
                <span className="text-muted">Edit</span>
            </NavLink>
        </li>
    );
}

export default Item;
