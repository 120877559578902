import React, {CSSProperties} from "react";
import {IonIcon} from "@ionic/react";

export function IconWithConfirmation(props: {
    icon: string,
    className?: string,
    style?: CSSProperties,
    buttonClassName?: string,
    confirming: boolean,
    setConfirming: (confirming: boolean) => void,
}) {
    if (props.confirming) {
        return <></>;
    }

    return <div className={"text-center"}>
        <IonIcon icon={props.icon} className={"pointer " + props.className}
                 style={props.style}
                 onClick={() => props.setConfirming(true)}/>
    </div>;
}

export default IconWithConfirmation;
