import React from "react";
import {IonIcon} from "@ionic/react";
import {medkitOutline} from "ionicons/icons";
import {Link} from "react-router-dom";

function Welcome(): JSX.Element {
    return (
        <div className="section homepage">
            <div className="splash-page mt-5 mb-5">
                <div className="iconbox mb-3">
                    <IonIcon icon={medkitOutline} role="img" className="md hydrated"/>
                </div>
                <h2 className="mb-2">MyEPR</h2>
                <p className={"text-dark"}>Welcome!</p>
                <div className={"mt-5"}>
                    <p>Test user? <Link to={"/logout"}>Logout</Link>.</p>
                </div>
            </div>
        </div>
    );
}

export default Welcome;
