import {useNavigate} from "react-router-dom";
import {addOutline, buildOutline, documentTextOutline, trendingDownOutline} from "ionicons/icons";
import BigButton from "../../components/Buttons/BigButton";

function Actions(props: { className?: string, withAdd: boolean, addOnly: boolean }): JSX.Element {
    const {withAdd, addOnly} = props;
    const navigate = useNavigate();

    return (<>
        <div className={"section mt-2"}>
            <div className="row">
                {addOnly ?
                    <>
                        <div className="section mt-4 text-center">
                            <h2 className="text-dark" style={{textWrap: "balance"}}>
                                Manage your medications
                            </h2>
                            <p className="text-secondary mt-4" style={{textWrap: "balance"}}>
                                Add your medications to get started, enter your dose schedule,
                                and record what you take and when in your Electronic Patient Record.
                            </p>
                            <p className="text-secondary mt-2" style={{textWrap: "balance"}}>
                                The system will keep track of your stock levels, and forecast when you need to order
                                more.
                            </p>
                        </div>
                        <div className="mt-2 mb-2">
                            <BigButton style={{textAlign: "left"}} icon={addOutline} label={"Add your first medication"}
                                       onClick={() => navigate("/medications/manage/edit/new")}/>
                        </div>
                    </>
                    :
                    <>
                        <div className="col-4 mb-2">
                            {withAdd ?
                                <BigButton style={{textAlign: "left"}} icon={addOutline}
                                           label={"Add medication"}
                                           onClick={() => navigate("/medications/manage/edit/new")}/>
                                :
                                <BigButton icon={buildOutline} label={"Manage"}
                                           onClick={() => navigate("/medications/manage")}/>
                            }
                        </div>
                        <div className="col-4 mb-2">
                            <BigButton icon={trendingDownOutline} label={"Forecast stock"}
                                       onClick={() => navigate("/medications/stock/forecast")}/>
                        </div>
                        <div className="col-4 mb-2">
                            <BigButton icon={documentTextOutline} label={"View summary"}
                                       onClick={() => navigate("/medications/summary")}/>
                        </div>
                    </>
                }
            </div>
        </div>
    </>);
}

export default Actions;
