import {IonIcon} from "@ionic/react";
import './Buttons.css';

function BigButton(props: {
    className?: string,
    style?: React.CSSProperties,
    icon?: string,
    label?: string,
    onClick: () => void,
}): JSX.Element {
    return (
        <div className={props.className + " big-button bill-box pt-1 pb-1 ps-1 pe-1 pointer"} onClick={props.onClick}>
            {props.icon &&
                <IonIcon icon={props.icon} className={"text-primary"} style={{fontSize: "3em", ...props.style}}/>}
            {props.label &&
                <div className={"text-primary"} style={{fontSize: "0.7rem", lineHeight: "1rem"}}>{props.label}</div>}
        </div>
    );
}

export default BigButton;
