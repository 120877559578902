import {baseUrl, getAxiosRequestOptions, handleAxiosError} from "./base";
import axios from "axios";
import {HelpItem} from "../types/Help";

export const fetchHelpQueryOptionsFunc = (id: string) => ({
    queryKey: ["help", id],
    queryFn: () => fetchHelp(id),
    staleTime: 60 * (60 * 1000),
    gcTime: 60 * (60 * 1000),
    refetchOnWindowFocus: false,
    enabled: false,
});

export const fetchHelp = async (id: string): Promise<HelpItem> => {
    if (id === "") {
        return {
            id: "",
            title: "",
            content: "",
        };
    }
    return axios.post(`${baseUrl}/help/read`, {"id": id}, getAxiosRequestOptions())
        .then((res) => res.data)
        .catch(handleAxiosError("Failed to load help content"));
}
