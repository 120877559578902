import React from "react";
import ForecastTimelineItem from "./ForecastTimelineItem";
import {MedicationStockForecast} from "../../../types/MedicationVariants";

function ForecastTimeline(props: {
    forecast: Array<{ days: number, medications: MedicationStockForecast[] }>
}): JSX.Element {
    return (
        <div className="timeline timed ms-1 me-2" style={{flexGrow: 1}}>
            {props.forecast.map(({days, medications}) =>
                <ForecastTimelineItem key={days.toString()} daysLeft={days} meds={medications}/>)}
        </div>
    );
}

export default ForecastTimeline;
