import React, {useEffect} from "react";
import StockOptions from "./StockOptions";

function StockChangeField(props: {
    options?: (number | undefined)[],
    style?: React.CSSProperties,
    label: string | JSX.Element,
    labelStyle?: React.CSSProperties,
    value: number,
    change: number,
    setChangeFunction: (value: number) => void,
    inputStyle?: React.CSSProperties,
    helpId?: string,
    withPopups?: boolean,
    isDirty?: boolean,
    setIsDirty?: (isDirty: boolean) => void,
    allowSave?: boolean,
    setAllowSave?: (isDirty: boolean) => void,
}): JSX.Element {
    const {value, change, setAllowSave} = props;
    const [newTotal, setNewTotal] = React.useState<number>(props.value);
    let changeOptions: (number | undefined)[] = props.options ? props.options : [1, 10, 16, 28];

    useEffect(() => {
        let newTotal = value + change;
        if (setAllowSave) setAllowSave(newTotal >= 0);
        setNewTotal(newTotal);
    }, [setAllowSave, value, change]);

    useEffect(() => {
        setNewTotal(props.value);
    }, [props.value]);

    return (
        <>
            <div className="form-group basic text-dark"
                 style={{display: "flex", justifyContent: "center", paddingTop: 0, ...props.style}}>
                <div className={"text-center"} style={{flexGrow: 1}}>
                    Stock: <span className={"text-secondary"}>{props.value}</span>
                    <div className={"text-center " + (props.change < 0 ? "text-dqnger" : "text-success")}
                         style={{display: "inline-block", minWidth: "5rem", fontWeight: "bolder"}}>
                        {props.change < 0 ? "-" : "+"} {Math.abs(props.change)}
                    </div>
                    =
                    <div className={"text-center " + (newTotal < 0 ? "text-danger" : "text-primary")}
                         style={{display: "inline-block", width: "3.5rem", fontWeight: "bolder"}}>
                        {newTotal}
                    </div>
                </div>
            </div>
            <StockOptions value={props.change} setFunction={(newValue) => props.setChangeFunction(newValue)}
                          options={changeOptions} withPopups={props.withPopups!}/>
        </>
    );
}

export default StockChangeField;
