function LoadingError(props: { message: string, retryAction: () => void }) {
    const {message, retryAction} = props;

    return (<>
        <div className="card">
            <div className="card-body">
                <div className="text-center">
                    <h1 className="cart-title text-danger">Error</h1>
                    <p className="card-text text-danger">{message}</p>
                    <button className="btn btn-primary" onClick={retryAction}>Try again
                    </button>
                </div>
            </div>
        </div>
    </>);
}

export default LoadingError;
