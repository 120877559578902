import React from "react";
import HeaderWithBackButton from "../../../components/Header/HeaderWithBackButton";
import "./ForecastStock.css";
import {fetchStockForecast} from "../../../api/medication-stock-forecast";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import LoadingError from "../../../components/LoadingError/LoadingError";
import ForecastTimeline from "./ForecastTimeline";

export interface ForecastByDuration {
    days: number;
    names: string[];
}

function ForecastStock(): JSX.Element {
    const queryClient = useQueryClient();
    const {
        isLoading: loading,
        isError: isLoadingError,
        data: forecast,
        error: loadingError,
    } = useQuery({
        queryKey: ["medications", "forecast"],
        queryFn: fetchStockForecast,
        staleTime: 10 * (60 * 1000),
        gcTime: 15 * (60 * 1000),
    })

    return (
        <>
            <HeaderWithBackButton title="Medication Stock Forecast"/>
            {isLoadingError && <div className="section full mb-2">
                <LoadingError message={loadingError.message}
                              retryAction={() => queryClient.invalidateQueries({queryKey: ["forecast"]})}/>
            </div>}
            {loading && <h1 className={"splash-page mt-5"}>
                <div className="spinner-border text-primary" style={{width: "60px", height: "60px"}}
                     role="status"></div>
            </h1>}
            {!loading && !isLoadingError && <div className="section full">
                <div className="card">
                    <div className="card-body" style={{padding: "0"}}>
                        <ForecastTimeline forecast={forecast!}/>
                    </div>
                </div>
            </div>}
        </>
    );
}

export default ForecastStock;