import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {removePostAuthDest, removeUserToken} from "../../auth/store";
import {FullScreenLoader} from "./FullScreenLoader";

export const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            removeUserToken();
            removePostAuthDest();
            navigate("/");
        }, 500);
    }, [navigate]);

    return <FullScreenLoader title={"Logging out.."}/>;
};
