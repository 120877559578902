import React from "react";

function StockOptions(props: {
    value: number,
    setFunction: (value: number) => void,
    options: (number | undefined)[],
    withPopups: boolean,
    style?: any,
}): JSX.Element {
    return (
        <div style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            gap: "0.25rem",
            justifyContent: "center",
            ...props.style,
        }}>
            <div className={"flex-break"}></div>
            {props.options.map((option, idx) =>
                option === undefined ? <div key={idx} className={"flex-break"}></div> :
                    option === 0 ?
                        <button key={idx} type="button"
                                className={"btn btn-outline-danger btn-sm"}
                                onClick={() => props.setFunction(0)}>
                            =0
                        </button>
                        :
                        <button key={idx} type="button"
                                className={"btn btn-outline-" + (option < 0 ? "danger" : "secondary") + " btn-sm"}
                                onClick={() => props.setFunction(props.value + option)}>
                            {option < 0 ? "" : "+"}{option}
                        </button>
            )}
            {props.withPopups && <>
                <button type="button" className="btn btn-outline-secondary btn-sm"
                        data-bs-toggle="modal" data-bs-target="#add-stock">+?
                </button>
                <button type="button" className="btn btn-outline-danger btn-sm"
                        data-bs-toggle="modal" data-bs-target="#subtract-stock">-?
                </button>
            </>}
        </div>
    );
}

export default StockOptions;
