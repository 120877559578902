import {QueryClient} from "@tanstack/react-query";
import {
    MedicationSchedule,
    MedicationsTaken,
    MedicationTakenErrors,
    MedicationVariantSuggested,
    ScheduledDoseStatusPending,
    ScheduledDoseStatusSkipped,
    ScheduledDoseStatusTaken,
    TakenEntry
} from "../types/MedicationSchedules";
import {
    fetchPendingTakeNotesOptionsFunc,
    fetchPendingTakeQuantityQueryOptionsFunc,
    fetchPendingTakeStatusQueryOptionsFunc,
    invalidatePendingTakeCache
} from "./medication-pending-take";
import {getCurrentDate, getCurrentTime} from "../utils/DateTime";
import {fetchScheduleQueryFunc} from "./medication-schedules";
import {baseUrl, getAxiosRequestOptions, handleAxiosError} from "./base";
import axios from "axios";
import {invalidateMedicationsCache} from "./medications";
import {invalidateEprCache} from "./entries";

export const submitMedicationsTaken = async (queryClient: QueryClient, date: string, time: string, dateTaken: string, timeTaken: string, medicationIds: string[]): Promise<MedicationTakenErrors> => {
    // Gather the data for the request
    let body: MedicationsTaken = {
        date: date,
        time: time,
        dateTaken: dateTaken === "now" ? getCurrentDate() : dateTaken,
        timeTaken: timeTaken === "now" ? getCurrentTime() : timeTaken,
        medications: [],
        notes: queryClient.getQueryData(fetchPendingTakeNotesOptionsFunc(date, time, "").queryKey) as string,
    };

    let schedule = queryClient.getQueryData(fetchScheduleQueryFunc(date, time, true).queryKey) as MedicationSchedule;
    for (let dose of schedule.items) {
        let status = queryClient.getQueryData(fetchPendingTakeStatusQueryOptionsFunc(date, time, dose.medicationId).queryKey);
        if (status !== ScheduledDoseStatusSkipped && status !== ScheduledDoseStatusTaken) {
            continue;
        }

        let scheduleItem = schedule.items.find((item) => item.medicationId === dose.medicationId);
        if (!scheduleItem || scheduleItem.status !== ScheduledDoseStatusPending) {
            continue;
        }

        let medicationTaken: TakenEntry = {
            medicationId: dose.medicationId,
            newStatus: status,
            variants: [],
        };

        if (status === ScheduledDoseStatusTaken) {
            let variants: MedicationVariantSuggested[] = scheduleItem.suggestedVariants;
            if (!variants) {
                continue;
            }

            for (let variant of variants) {
                let quantityTaken = queryClient.getQueryData(fetchPendingTakeQuantityQueryOptionsFunc(queryClient, date, time, dose.medicationId, variant.strength, variant.unit, variant.form, variant.suggestedQuantity).queryKey) as number;
                if (quantityTaken === 0) {
                    continue;
                }

                medicationTaken.variants.push({
                    strength: variant.strength,
                    unit: variant.unit,
                    form: variant.form,
                    quantity: quantityTaken,
                    // TODO: Let the user supply this value!
                    wasted: variant.minimumWastage,
                });
            }
        }

        if (status === ScheduledDoseStatusSkipped || medicationTaken.variants.length > 0) {
            body.medications.push(medicationTaken);
        }
    }

    const url = `${baseUrl}/medications/take`;
    return axios.post(url, body, getAxiosRequestOptions()).then((res): Promise<MedicationTakenErrors> => {
        // Invalidate the take cache.
        invalidatePendingTakeCache(queryClient);
        // Invalidate all cached medication queries.
        invalidateMedicationsCache(queryClient);
        // And invalidate the entries cache.
        invalidateEprCache(queryClient);
        return Promise.resolve(res.data.errors);
    }).catch(handleAxiosError(body.medications.length > 0 ? "Failed to record medication" + (body.medications.length !== 1 ? "s" : "") + " as taken" : "Failed to save notes"));
};
