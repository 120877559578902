import axios from "axios";
import {baseUrl, getAxiosRequestOptions, handleAxiosError} from "./base";
import {MedicationOptions} from "../types/Medication";

export const fetchMedicationOptionsQueryOptions = {
    queryKey: ["medications", "options"],
    queryFn: () => fetchMedicationOptions(),
    // These will very rarely change so cache for 24 hours.
    staleTime: 1440 * (60 * 1000),
    gcTime: 1440 * (60 * 1000),
};

export const fetchMedicationOptions = async (): Promise<MedicationOptions> => {
    return axios.post(`${baseUrl}/medications/options`, {}, getAxiosRequestOptions()).then((res) => {
        return {
            units: new Map<string, string>(Object.entries(res.data.units)),
            forms: new Map<string, string>(Object.entries(res.data.forms)),
        };
    }).catch(handleAxiosError("Failed to load medication options"));
};

export const optionsListSingular = (options: Map<string, string>): string[] => {
    return Array.from(options.keys()).sort((a, b) => a.localeCompare(b));
}

export const optionsListPlural = (options: Map<string, string>): string[] => {
    return Array.from(options.values()).sort((a, b) => a.localeCompare(b));
}

export const optionSingularToPlural = (options: Map<string, string>, num: number, option: string): string => {
    if (num === 1) {
        return option;
    }

    if (options.has(option)) {
        return options.get(option)!;
    }
    // Fallback to just adding a trailing 's'.
    return option + "s";
}

export const optionPluralToSingular = (options: Map<string, string>, option: string): string => {
    for (let [key, value] of options.entries()) {
        if (value === option) {
            return key;
        }
    }
    // Fallback to just removing the trailing 's'.
    if (option.endsWith("s")) {
        return option.slice(0, option.length - 1);
    }
    return option;
}
