import React, {useEffect} from "react";
import {useLogin} from "../../auth/useLogin";
import {useNavigate} from "react-router-dom";
import {FullScreenLoader} from "./FullScreenLoader";

export const Login = (props: { provider: string }) => {
    const {provider} = props;
    const login = useLogin();
    const navigate = useNavigate();

    useEffect(() => {
        login(provider).then((url: string) => {
            if (!url) {
                url = "/";
            }
            if (url.startsWith("/")) {
                navigate(url);
            } else {
                window.location.href = url;
            }
        });
    }, [login, provider, navigate]);

    return <FullScreenLoader title={"Loading..."}/>;
};
