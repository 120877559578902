import {baseUrl, getAxiosRequestOptions, handleAxiosError} from "../api/base";
import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import * as store from "./store";
import axios from "axios";

const getUser = async (): Promise<User> => {
    return axios.post(
        `${baseUrl}/user/read`,
        {},
        getAxiosRequestOptions(),
    )
        .then((res) => res.data)
        .catch(handleAxiosError("Failed to load medications"));
};

export interface User {
    id: string;
    email: string;
    avatarUrl: string;
    createdAt: string;
}

interface IUseUser {
    user: User | null;
    isLoading: boolean;
}

export function useUser(): IUseUser {
    const {
        data: user,
        isLoading,
    } = useQuery({
        queryKey: ["user"],
        queryFn: () => getUser(),
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
    });
    const [returnedUser, setReturnedUser] = useState<User | null>(store.getUser());

    useEffect(() => {
        if (user) {
            store.setUser(user);
            setReturnedUser(user);
        }
    }, [user]);

    return {
        user: returnedUser,
        isLoading: isLoading,
    }
}
