import React from 'react';
import Header from "../../components/Header/Header";
import {searchOutline} from "ionicons/icons";
import EntryDisplay from "./Entry";
import {dateFromDate} from "../../utils/DateTime";
import {useFetchEntriesInfiniteQuery} from "../../api/entries";
import {useNavigate} from "react-router-dom";
import LoadingWrapper from "../../components/Loader/LoadingWrapper";
import IntroScreen from "./IntroScreen";
import PullToRefreshWrapper from "../../components/PullToRefreshWrapper/PullToRefreshWrapper";

function Entries(): JSX.Element {
    const navigate = useNavigate();
    const entriesQuery = useFetchEntriesInfiniteQuery(10);

    let currentDate = "";

    return (<>
        <Header title={"Electronic Patient Record"}
                rightIcon={searchOutline} rightAction={() => navigate("/entries/add")}/>
        <LoadingWrapper isLoading={entriesQuery.isLoading} hasError={entriesQuery.isError}
                        errorMessage={entriesQuery.isError ? entriesQuery.error.message : ""}
                        onRetry={entriesQuery.refetch}>
            {entriesQuery.data && entriesQuery.data.pages && entriesQuery.data.pages[0].entries ?
                <PullToRefreshWrapper onRefresh={entriesQuery.refetch}
                                      canFetchMore={entriesQuery.data.pages[entriesQuery.data.pages.length - 1].nextEntryWhen !== ""}
                                      onFetchMore={entriesQuery.fetchNextPage}>
                    <>
                        {entriesQuery.data.pages.map((page, _) => page.entries.map((entry, _) => {
                            let entryDate = dateFromDate(new Date(Date.parse(entry.when)));
                            let showDate = entryDate !== currentDate;
                            currentDate = entryDate;
                            return <EntryDisplay key={entry.id} showDate={showDate} entry={entry}/>;
                        }))}
                    </>
                </PullToRefreshWrapper>
                :
                <IntroScreen/>
            }
        </LoadingWrapper>
    </>);
}

export default Entries;
