import React, {useEffect} from "react";
import {IonIcon} from "@ionic/react";
import {addOutline, removeOutline} from "ionicons/icons";
import {MedicationVariantSuggested, ScheduleItem} from "../../../types/MedicationSchedules";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {fetchPendingTakeQuantityQueryOptionsFunc, setPendingTakeQuantity} from "../../../api/medication-pending-take";

const TakeMedicationVariantItem = (props: {
    className?: string,
    date: string,
    time: string,
    medicationId: string,
    entry: ScheduleItem,
    variant: MedicationVariantSuggested,
    allowSkipped: boolean,
    updateDose: (dose: number) => void,
    resetDosesTrigger: number,
}) => {
    const {variant, updateDose, resetDosesTrigger} = props;
    const queryClient = useQueryClient();
    const {
        data: quantityTaken,
        refetch: refetchQuantityTaken
    } = useQuery(fetchPendingTakeQuantityQueryOptionsFunc(queryClient, props.date, props.time, props.medicationId,
        props.variant.strength, props.variant.unit, props.variant.form, props.variant.suggestedQuantity || 0));

    useEffect(() => {
        refetchQuantityTaken();
    }, [resetDosesTrigger, refetchQuantityTaken]);

    useEffect(() => {
        if (quantityTaken !== undefined) {
            updateDose(quantityTaken * variant.strength);
        }
    }, [quantityTaken, variant]); // eslint-disable-line react-hooks/exhaustive-deps

    const adjustQuantityTaken = (amount: number) => {
        let currentValue = quantityTaken || 0;
        if (currentValue + amount >= 0) {
            setPendingTakeQuantity(
                queryClient,
                props.date,
                props.time,
                props.medicationId,
                props.variant.strength,
                props.variant.unit,
                props.variant.form,
                currentValue + amount).then(undefined);
        }
    };

    return (
        <div className={props.className}
             style={{
                 marginRight: "0.7rem",
                 display: "flex",
                 flexWrap: "nowrap",
                 gap: "1rem",
                 justifyContent: "right",
                 alignItems: "center"
             }}>
            <div style={{textAlign: "right"}}>
                {props.variant.strength} {props.entry.medicationUnit} <span
                style={{textWrap: "nowrap"}}>{props.variant.form}</span>
            </div>
            <div>
                {props.variant.stock === 0 ?
                    <span className="badge bg-danger">out of stock</span>
                    :
                    <div className="btn-group" role="group">
                        <button type="button" className="btn btn-icon btn-secondary"
                                onClick={() => adjustQuantityTaken(props.variant.canBeHalved ? -0.5 : -1)}>
                            <IonIcon icon={removeOutline} role="img" className="md hydrated"
                                     aria-label="add outline"/>
                        </button>
                        <button className={"btn btn-outline-secondary square-left square-right text-primary"}
                                style={{fontSize: "1rem", width: "3rem"}}>
                            {quantityTaken}
                        </button>
                        <button type="button" className="btn btn-icon btn-secondary square-left"
                                onClick={() => adjustQuantityTaken(props.variant.canBeHalved ? +0.5 : +1)}>
                            <IonIcon icon={addOutline} role="img" className="md hydrated"
                                     aria-label="add outline"></IonIcon>
                        </button>
                    </div>}
            </div>
        </div>
    );
};

export default TakeMedicationVariantItem;
