import HorizontalBigButton from "../../../components/Buttons/HorizontalBigButton";
import React, {useEffect} from "react";
import TakeList from "./TakeList";
import {useNavigate} from "react-router-dom";
import DateNavigation from "./DateNavigation";
import {useQuery} from "@tanstack/react-query";
import {countPendingScheduledDoses} from "../../../api/medication-schedules";
import {getClosestTime, getCurrentDate, getCurrentTime} from "../../../utils/DateTime";
import LoadingWrapper from "../../../components/Loader/LoadingWrapper";

function TakeTimeTabs(props: {
    selectedDate: string,
    selectedTime: string,
    setHasMedications: (hasMedications: boolean) => void,
}): JSX.Element {
    const navigate = useNavigate();
    const {selectedDate, selectedTime, setHasMedications} = props;

    const {
        isLoading: loading,
        isError: isLoadingError,
        data: doseTimesWithPendingCount,
        error: loadingError,
        refetch,
    } = useQuery({
        queryKey: ["medications", "schedules", selectedDate],
        queryFn: () => countPendingScheduledDoses(selectedDate),
        staleTime: 10 * (60 * 1000),
        gcTime: 15 * (60 * 1000),
    })

    useEffect(() => {
        if (doseTimesWithPendingCount && doseTimesWithPendingCount.length > 0) {
            if (!selectedTime) {
                let nowTime = getCurrentTime();
                if (doseTimesWithPendingCount.length > 0) {
                    navigate("/medications/" + selectedDate + "/" + getClosestTime(nowTime, doseTimesWithPendingCount.map((entry) => entry[0])));
                    return;
                }
            }

            if (selectedTime === "unscheduled" && selectedDate !== getCurrentDate()) {
                navigate("/medications/" + selectedDate + "/" + doseTimesWithPendingCount[0][0]);
                return;
            }
        }
    }, [navigate, doseTimesWithPendingCount, selectedDate, selectedTime]);

    useEffect(() => {
        if (doseTimesWithPendingCount !== undefined) {
            setHasMedications(doseTimesWithPendingCount.length > 0);
        }
    }, [setHasMedications, doseTimesWithPendingCount]);

    return (<>
        {doseTimesWithPendingCount && doseTimesWithPendingCount.length > 0 &&
            <DateNavigation date={props.selectedDate} time={props.selectedTime}/>}
        <LoadingWrapper isLoading={loading} hasError={isLoadingError}
                        errorMessage={loadingError ? loadingError.message : ""}
                        onRetry={refetch}>
            {doseTimesWithPendingCount && doseTimesWithPendingCount.length > 0 && <>
                <div style={{display: "flex", gap: "0.75rem", justifyContent: "center", flexWrap: "wrap"}}>
                    {doseTimesWithPendingCount && doseTimesWithPendingCount.map((entry, idx) =>
                        <HorizontalBigButton label={entry[0] === "unscheduled" ? "as needed" : entry[0]} key={idx}
                                             className={props.selectedTime === entry[0] ? "btn-primary" : ""}
                                             textClassName={props.selectedTime === entry[0] ? "text-light" : (!entry[1] ? "text-secondary" : "")}
                                             style={(!props.selectedTime || props.selectedTime === "") ? {} : {
                                                 borderBottomLeftRadius: 0,
                                                 borderBottomRightRadius: 0
                                             }}
                                             onClick={() => navigate("/medications/" + props.selectedDate + "/" + entry[0])}/>
                    )}
                </div>
                <div className="border-primary" style={{borderTop: "2px solid"}}>
                    {doseTimesWithPendingCount.map((entry, _) =>
                        <TakeList key={entry[0]} date={props.selectedDate} time={entry[0]}
                                  selected={props.selectedTime === entry[0]}
                                  allowSkipped={entry[0] !== "unscheduled"}/>)}
                </div>
            </>}
        </LoadingWrapper>
    </>);
}

export default TakeTimeTabs;
