import React, {CSSProperties} from "react";

export function Confirmation(props: {
    style?: CSSProperties,
    areYouSureLabel: string,
    confirmLabel: string,
    buttonClassName?: string,
    confirming: boolean,
    setConfirming: (confirming: boolean) => void,
    confirmedAction: () => void,
}) {
    const buttonClassName = props.buttonClassName ? props.buttonClassName : "btn-danger";

    if (!props.confirming) {
        return <></>;
    }

    return <div style={props.style}>
        <div className={"card-title text-center " + buttonClassName.replaceAll("btn-", "text-")}
             style={{textWrap: "balance"}}>
            {props.areYouSureLabel}
        </div>
        <div style={{
            display: "flex",
            gap: "1rem",
            justifyContent: "center"
        }}>
            <button type="button"
                    className="btn btn-secondary"
                    onClick={() => props.setConfirming(false)}>
                Cancel
            </button>
            <button type="button"
                    className={"btn " + buttonClassName}
                    style={{textWrap: "balance"}}
                    onClick={() => {
                        props.setConfirming(false);
                        props.confirmedAction();
                    }}>
                {props.confirmLabel}
            </button>
        </div>
    </div>;
}

export default Confirmation;
