import React from "react";
import randomString from "../../utils/RandomString";
import "./Field.css";
import DaysField from "./DaysField";

function IntField(props: {
    type: string,
    label: string,
    value: number,
    setFunction: (newValue: number) => void
}): JSX.Element {
    const {type, label, value, setFunction} = props;
    const randomId = randomString(8)

    const dayIntervals = (): Array<number> => {
        let res: Array<number> = [];
        for (let i = 1; i < 91; i++) {
            res.push(i);
        }
        return res;
    }

    const textForDayInterval = (v: number): string => {
        switch (v) {
            case 1:
                return "day";
            case 2:
                return "other day";
            default:
                return v.toString() + " days";
        }
    }

    const setDay = (day: number, checked: boolean): void => {
        if (checked) {
            setFunction(value | (1 << day));
        } else {
            setFunction(value & ~(1 << day));
        }
    }

    switch (type) {
        case "days":
            return <DaysField value={value} setFunction={setDay}/>;

        case "interval":
            return <div className="form-group basic mb-2">
                <div className="input-wrapper" style={{display: "flex", gap: "4px", justifyContent: "center"}}>
                    <select className="form-control custom-select text-primary"
                            style={{width: "inherit", paddingRight: "1.5rem"}}
                            id="randomId" defaultValue={value}
                            onChange={(e) => setFunction(parseInt(e.target.value))}>
                        {dayIntervals().map((v: number, _: number) => {
                            return <option key={v} value={v}>Every {textForDayInterval(v)}</option>;
                        })}
                    </select>
                </div>
            </div>;

        default:
            return <div className="form-group basic">
                <div className="input-wrapper text-center">
                    <label className="label" htmlFor={randomId}>{label}</label>
                    <input type="number" className="form-control" id={randomId} value={value}
                           onChange={(e) => setFunction(parseInt(e.target.value))}/>
                </div>
            </div>;
    }
}

export default IntField;
