import axios from "axios";
import {baseUrl, getAxiosRequestOptions, handleAxiosError} from "./base";
import {MedicationDose} from "../types/Medication";
import {QueryClient} from "@tanstack/react-query";

export const fetchMedicationDoses = async (queryClient: QueryClient, medicationId: string): Promise<MedicationDose[]> => {
    return axios.post(`${baseUrl}/medications/doses/list`, {"medicationId": medicationId}, getAxiosRequestOptions()).then((res) => {
        if (!res.data.doses) {
            return [];
        }
        const doses: MedicationDose[] = res.data.doses;
        queryClient.setQueryData(["medications", medicationId, "doses"], doses);
        return doses;
    }).catch(handleAxiosError("Failed to load medication doses"));
};

export const saveMedicationDose = async (medicationId: string, time: string, dose: number): Promise<MedicationDose> => {
    if (medicationId === "" || time === "" || dose === 0) {
        throw new Error("All fields are required");
    }

    let body: MedicationDose = {
        medicationId: medicationId,
        time: time,
        dose: dose,
    };

    let url = `${baseUrl}/medications/doses/write`;
    return axios.post(url, body, getAxiosRequestOptions()).then((res): Promise<MedicationDose> => {
        return Promise.resolve(res.data);
    }).catch(handleAxiosError("Failed to save medication dose"));
};

export const deleteMedicationDose = async (medicationId: string, time: string): Promise<void> => {
    let url = `${baseUrl}/medications/doses/delete`;
    let body = {
        medicationId: medicationId,
        time: time,
    };

    return axios.post(url, body, getAxiosRequestOptions()).then((res): Promise<void> => {
        return Promise.resolve();
    }).catch(handleAxiosError("Failed to delete medication dose"));
};
