import React, {useEffect} from "react";
import {TakeScheduledMedicationsEntry} from "../../types/Entry";
import {EntryWrapper} from "./Entry";
import {fetchMedicationOptionsQueryOptions, optionSingularToPlural} from "../../api/medication-options";
import {useQuery} from "@tanstack/react-query";
import {timeFromDate} from "../../utils/DateTime";

export default function EntryTakeUnscheduledMedications(props: {
    showDate: boolean,
    when: string,
    data: TakeScheduledMedicationsEntry
}) {
    const {data} = props;
    const {data: lookups} = useQuery(fetchMedicationOptionsQueryOptions);
    const [timeTaken, setTimeTaken] = React.useState<string>("");

    useEffect(() => {
        let parsed = new Date(Date.parse(props.when));
        setTimeTaken(timeFromDate(parsed));
    }, [props.when]);

    if (data.items.length === 0) {
        if (props.showDate) {
            return <EntryWrapper date={props.when}/>;
        }
        return <></>;
    }

    return (
        <EntryWrapper date={props.showDate ? props.when : ""}>
            <div className="card">
                <div className="card-body ps-0 pe-0 pb-0">
                    <div className="ms-2 me-2" style={{display: "flex", gap: "0.5rem", alignItems: "center"}}>
                        <img src={"/temp/pill-duotone.svg"} alt="medication dose"
                             style={{width: "40px", height: "40px"}}/>
                        <div style={{flexGrow: 1}}>
                            <h5 className="card-title">Medication{data.items.length !== 1 ? "s" : ""} taken</h5>
                            <h6 className="card-subtitle">{timeTaken}</h6>
                        </div>
                    </div>
                    <div className="timeline timed mt-2"
                         style={{paddingBottom: 0, paddingTop: "8px", borderTop: "1px solid #ccc"}}>
                        <div className="item pb-1 text-dark mb-0">
                            <div className="time text-dark"></div>
                            <div className="dot bg-success" style={{marginTop: "4px"}}></div>
                            <div className="content">
                                <table>
                                    <tbody>
                                    {data.items.map(med =>
                                        <tr key={med.medication_id}>
                                            <td style={{
                                                textAlign: "right",
                                                paddingRight: "0.5rem",
                                                minWidth: "4.5rem",
                                            }}>{med.strength} {lookups ? optionSingularToPlural(lookups.units, med.strength, med.unit) : med.unit}</td>
                                            <td>{med.name}</td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {data.notes !== "" &&
                            <div className="item pb-1">
                                <div className="time" style={{textAlign: "right"}}>Notes</div>
                                <div className="content" style={{
                                    fontSize: "0.8rem",
                                    lineHeight: "1.1rem",
                                    paddingTop: "2px",
                                }}>{data.notes}</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </EntryWrapper>
    );
}
