import React, {useEffect} from 'react';
import Header from "../../components/Header/Header";
import {useNavigate, useParams} from "react-router-dom";
import TakeSection from "./Take/TakeSection";
import {getCurrentDate} from "../../utils/DateTime";
import Loader from "../../components/Loader/Loader";

function MedicationsHome(): JSX.Element {
    const {date, time} = useParams() as { date: string, time: string };
    const navigate = useNavigate();

    useEffect(() => {
        if (!date) {
            navigate("/medications/" + getCurrentDate());
        }
    }, [navigate, date]);

    if (!date) {
        return <Loader show={true}/>;
    }

    return (<>
        <Header title={"Medications"} helpId={"medications"}/>
        <TakeSection date={date} time={time}/>
    </>);
}

export default MedicationsHome;
