import axios from "axios";
import {baseUrl, getAxiosRequestOptions, handleAxiosError} from "./base";
import {QueryClient} from "@tanstack/react-query";

export const clearCache = async (queryClient: QueryClient): Promise<number> => {
    return axios.post(`${baseUrl}/cache/clear`, {}, getAxiosRequestOptions()).then((res) => {
        if (!res.data.count) {
            return 0;
        }
        return res.data.count;
    }).catch(handleAxiosError("Failed to clear cache"));
};
