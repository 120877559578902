import React from 'react';
import './AddEntry.css';
import MedicationsCard from "./MedicationsCard";
import {getCurrentDate} from "../../../utils/DateTime";
import HeaderWithBackButton from "../../../components/Header/HeaderWithBackButton";

function AddEntry(): JSX.Element {
    const today: string = getCurrentDate();

    return (
        <>
            <HeaderWithBackButton title={"Add Entry"}/>
            <MedicationsCard today={today}/>
            {/*<AppointmentsCard today={today}/>*/}
            {/*<MeasurementCard/>*/}
            {/*<GenericCard/>*/}
            <div className={"mt-2"}></div>
        </>
    );
}

export default AddEntry;
