import React, {useEffect, useState} from "react";
import Medication from "../../../../../types/Medication";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {dismissModal} from "../../../../../utils/DismissModal";
import {deleteMedicationVariant, saveMedicationVariant} from "../../../../../api/medication-variants";
import {MedicationVariant} from "../../../../../types/MedicationVariants";
import {fetchMedicationOptionsQueryOptions, optionSingularToPlural} from "../../../../../api/medication-options";
import {invalidateMedicationsCache} from "../../../../../api/medications";
import BoolSwitchField from "../../../../../components/Fields/BoolSwitchField";
import FloatField from "../../../../../components/Fields/FloatField";
import {trashBinOutline} from "ionicons/icons";
import IconWithConfirmation from "../../../../../components/ActionWithConfirmation/IconWithConfirmation";
import Confirmation from "../../../../../components/ActionWithConfirmation/Confirmation";
import StockChangeField from "../../../../../components/Fields/StockChangeField";

function EditVariantActionSheet(props: {
    medication: Medication,
    variant: MedicationVariant,
}): JSX.Element {
    const {medication, variant} = props;
    const [strength, setStrength] = useState<number>(variant ? variant.strength : 0);
    const [form, setForm] = useState<string>(variant ? variant.form : "tablets");
    const [canBeHalved, setCanBeHalved] = useState<boolean>(variant ? variant.canBeHalved : false);
    const [minimumWastage, setMinimumWastage] = useState<number>(variant ? variant.minimumWastage : 0);
    const [stock, setStock] = useState<number>(variant ? variant.stock : 0);
    const [stockChange, setStockChange] = useState<number>(0);
    const [allowSave, setAllowSave] = React.useState<boolean>(true);
    const [confirmingDelete, setConfirmingDelete] = React.useState<boolean>(false);
    const {data: lookups} = useQuery(fetchMedicationOptionsQueryOptions);
    const queryClient = useQueryClient();
    const saveMedicationVariantMutation = useMutation({
        mutationFn: () => saveMedicationVariant(
            medication.medicationId!,
            medication.name,
            medication.unit,
            strength, form,
            canBeHalved,
            minimumWastage,
            stock),
        onSuccess: (_: MedicationVariant) => {
            invalidateMedicationsCache(queryClient);
            setStockChange(0);
            dismissModal("edit-variant");
        },
        retry: 3,
    });
    const deleteMedicationVariantMutation = useMutation({
        mutationFn: () => deleteMedicationVariant(medication.medicationId!, variant.strength, variant.form),
        onSuccess: () => {
            invalidateMedicationsCache(queryClient);
            dismissModal("edit-variant");
        },
        retry: 3,
    });

    useEffect(() => {
        if (variant) {
            setStrength(variant.strength);
            setForm(variant.form);
            setCanBeHalved(variant.canBeHalved);
            setMinimumWastage(variant.minimumWastage ? variant.minimumWastage : 0);
            setStock(variant.stock ? variant.stock : 0);
        }
    }, [variant]);

    return (
        <div className="modal fade action-sheet" id={"edit-variant"} tabIndex={-1}
             aria-modal="true" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    {saveMedicationVariantMutation.isError && <div className="splash-page text-center mt-5 mb-5">
                        <h1 className="cart-title text-danger">Error</h1>
                        <p className="card-text text-danger">{saveMedicationVariantMutation.error.message}</p>
                        <button className="btn btn-primary" onClick={() => {
                            saveMedicationVariantMutation.mutate();
                        }}>Try again
                        </button>
                    </div>}
                    {(saveMedicationVariantMutation.isPending || deleteMedicationVariantMutation.isPending) &&
                        !saveMedicationVariantMutation.isError && !deleteMedicationVariantMutation.isError &&
                        <h1 className={"splash-page text-center mt-5 mb-5"}>
                            <div className="spinner-border text-primary" style={{width: "60px", height: "60px"}}
                                 role="status"></div>
                        </h1>}
                    {deleteMedicationVariantMutation.isError && <div className="splash-page text-center mt-5 mb-5">
                        <h1 className="cart-title text-danger">Error</h1>
                        <p className="card-text text-danger">{deleteMedicationVariantMutation.error.message}</p>
                        <button className="btn btn-primary" onClick={() => {
                            deleteMedicationVariantMutation.mutate();
                        }}>Try again
                        </button>
                    </div>}
                    {!saveMedicationVariantMutation.isPending && !saveMedicationVariantMutation.isError &&
                        !deleteMedicationVariantMutation.isPending && !deleteMedicationVariantMutation.isError && <>
                            <div className="modal-header" style={{position: "relative"}}>
                                <h5 className="modal-title">
                                    <IconWithConfirmation
                                        icon={trashBinOutline}
                                        style={{
                                            position: "absolute",
                                            top: "0.75rem",
                                            right: "1rem",
                                            fontSize: "1.15rem",
                                        }}
                                        className={"text-danger"}
                                        confirming={confirmingDelete} setConfirming={setConfirmingDelete}/>
                                    {medication.name} {strength} {medication.unit} {lookups ? optionSingularToPlural(lookups.forms, strength, form) : form}
                                </h5>
                            </div>
                            <div className="modal-body">
                                <div className="action-sheet-content">
                                    {!confirmingDelete && <>
                                        <form>
                                            <StockChangeField label={"Stock"} value={stock} change={stockChange}
                                                              style={{fontSize: "1.2rem"}}
                                                              options={[1, 10, 16, 28, 50, 90, undefined, 0, -1, -10, -16, -28, -50, -90]}
                                                              setChangeFunction={setStockChange} allowSave={allowSave}
                                                              setAllowSave={setAllowSave}/>
                                            <div style={{
                                                display: "flex",
                                                gap: "0.25rem",
                                                justifyContent: "center",
                                                marginTop: "1rem"
                                            }}>
                                                <BoolSwitchField label={"Can you take half of one?"}
                                                                 value={canBeHalved}
                                                                 setFunction={setCanBeHalved}/>
                                                <FloatField label={"Minimum wastage per dose"}
                                                            value={minimumWastage}
                                                            setFunction={setMinimumWastage}/>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                gap: "1rem",
                                                justifyContent: "center",
                                                marginTop: "1rem"
                                            }}>
                                                <button className={"btn btn-secondary"} data-bs-dismiss={"modal"}
                                                        onClick={(e) => e.preventDefault()}>
                                                    Cancel
                                                </button>
                                                <button className={"btn btn-primary"} disabled={!allowSave}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setStock(stock + stockChange);
                                                            saveMedicationVariantMutation.mutate();
                                                        }}>
                                                    Save changes...
                                                </button>
                                            </div>
                                        </form>
                                    </>}
                                    <Confirmation areYouSureLabel={"Are you sure you want to delete this variant?"}
                                                  confirmLabel={"Yes, delete it..."}
                                                  confirming={confirmingDelete}
                                                  setConfirming={setConfirmingDelete}
                                                  confirmedAction={() => {
                                                      deleteMedicationVariantMutation.mutate();
                                                  }}/>
                                </div>
                            </div>
                        </>}
                </div>
            </div>
        </div>
    );
}

export default EditVariantActionSheet;

