import React from "react";
import {IonIcon} from "@ionic/react";
import {medkitOutline} from "ionicons/icons";
import {Link} from "react-router-dom";
import {setPostAuthDest} from "../../auth/store";

function LoginRequired(): JSX.Element {
    setPostAuthDest(window.location.pathname);

    return (
        <div className="section homepage">
            <div className="splash-page mt-5 mb-5">
                <div className="iconbox mb-3">
                    <IonIcon icon={medkitOutline} role="img" className="md hydrated"/>
                </div>
                <h2 className="mb-2">MyEPR</h2>
                <p className={"text-danger"}>You must <Link to={"/login"}>login</Link> before you can view this page!
                </p>
            </div>
        </div>
    );
}

export default LoginRequired;
