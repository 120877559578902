import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {addDaysToDate, dateFromDate, formatFriendlyDate, getDateObject} from "../../../utils/DateTime";
import {IonIcon} from "@ionic/react";
import {arrowBack, arrowForward} from "ionicons/icons";

const DateNavigation = (props: { date: string, time: string }) => {
    const {date, time} = props;
    const navigate = useNavigate();
    const [yesterday, setYesterday] = useState<string>("");
    const [tomorrow, setTomorrow] = useState<string>("");

    useEffect(() => {
        let today = getDateObject(date, "12:00:00");
        setYesterday(dateFromDate(addDaysToDate(today, -1)));
        let tomorrow = addDaysToDate(today, +1);
        if (tomorrow <= new Date(Date.now())) {
            setTomorrow(dateFromDate(tomorrow));
        } else {
            setTomorrow("");
        }
    }, [date]);

    return (
        <div className="text-primary text-center mb-1" style={{display: "flex"}}>
            <div style={{textAlign: "left"}}>
                <button className="btn btn-link" disabled={yesterday === ""}
                        style={{visibility: yesterday === "" ? "hidden" : "visible"}}
                        onClick={() => navigate("/medications/" + yesterday + "/" + time)}>
                    <IonIcon icon={arrowBack}/>
                </button>
            </div>
            <div style={{flexGrow: 1, fontWeight: "bolder"}}>
                {formatFriendlyDate(getDateObject(date, "12:00:00"), true)}<br/>
            </div>
            <div style={{textAlign: "right"}}>
                <button className="btn btn-link" disabled={tomorrow === ""}
                        style={{visibility: tomorrow === "" ? "hidden" : "visible"}}
                        onClick={() => navigate("/medications/" + tomorrow + "/" + time)}>
                    <IonIcon icon={arrowForward}/>
                </button>
            </div>
        </div>
    );
}

export default DateNavigation;
